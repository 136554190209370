import React from "react";

export const YellowGraySquares = () => {
  return (
    <svg
      width="181"
      height="59"
      viewBox="0 0 181 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.972168" width="58" height="16" fill="#EFC059" />
      <rect y="21.9722" width="37" height="16" rx="2" fill="#EFC059" />
      <rect x="45" y="21.9722" width="13" height="16" fill="#EFC059" />
      <rect x="30" y="42.9722" width="28" height="16" rx="5" fill="#EFC059" />
      <rect y="42.9722" width="24" height="16" fill="#EFC059" />
      <path
        d="M89.684 37.8242V22.9202H91.304V37.8242H89.684ZM82.844 31.1642V29.6162H98.144V31.1642H82.844Z"
        fill="#F3F8F1"
      />
      <rect x="123" y="0.972168" width="58" height="58" fill="#3D5060" />
    </svg>
  );
};
