import React from "react";
import { Link } from "react-router-dom";

import { LogoFooter } from "../Svgs/LogoFooter";

const Footer = (props) => {
  let id = props.id;

  return (
    <footer
      id="contact"
      className="flex flex-col flex-wrap gap-5 py-36 text-white rounded-lg shadow md:flex justify-center items-center  opacity-1 text-center h-[482px] isolate w-full"
    >
      <div className="absolute box-border inset-x-0.bottom-0 inset-x-0  bg-[#1B1B1B] border-black-2">
        <LogoFooter className="z-10" />

        <div className="font-Raleway mt-5">
          <p className="font-black">2Wmedia GmbH</p>
          <p className="font-thin">Schleißheimer Straße 181c</p>
          <p className="mb-5 font-thin">80797 München</p>
          <p>
            <a className="underline font-thin" href="mailto:info@2wmedia.de">
              info@2wmedia.de
            </a>
          </p>
          <p className="mb-8 font-thin">Tel. +49 (0)89 30 90 45 60</p>
        </div>
        <div className="flex flex-row justify-center align-start gap-2 pb-14 text-[#3D5060] font-Raleway font-black">
          <p>
            {id === "impressum" ? (
              <Link to="/impressum" className="px-4 pointer-events-none">
                <span className="text-yellow-500">Impressum</span>
              </Link>
            ) : (
              <Link to="/impressum" className="px-4">
                <span className="link link-underline link-underline-black">
                  Impressum
                </span>
              </Link>
            )}
          </p>
          <p>|</p>
          <p>
            {id === "datenschutz" ? (
              <Link to="/datenschutz" className="px-4 pointer-events-none">
                <span className="text-yellow-500">Datenschutz</span>
              </Link>
            ) : (
              <Link to="/datenschutz" className="px-4">
                <span className="link link-underline link-underline-black">
                  Datenschutz
                </span>
              </Link>
            )}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
