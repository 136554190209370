import React from "react";

export const Comments = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2212)">
        <path
          d="M8.7 17.9999H3C1.507 17.9999 0 16.8659 0 14.3339V9.29389C0.0210272 6.99606 0.881427 4.7852 2.41918 3.07763C3.95693 1.37006 6.06592 0.283621 8.349 0.0228889C9.63736 -0.0706368 10.9308 0.114271 12.1413 0.565046C13.3518 1.01582 14.4512 1.7219 15.3646 2.63531C16.278 3.54871 16.9841 4.64804 17.4348 5.85859C17.8856 7.06913 18.0705 8.36253 17.977 9.65089C17.7159 11.9349 16.6286 14.0446 14.9198 15.5824C13.211 17.1203 10.9988 17.9801 8.7 17.9999ZM20 9.07989H19.988C19.988 9.31689 19.988 9.55389 19.976 9.79189C19.59 15.1999 14.647 19.7779 9.084 19.9809V19.9959C9.78461 21.211 10.7924 22.2206 12.0062 22.9233C13.2201 23.6261 14.5974 23.9973 16 23.9999H21C21.7956 23.9999 22.5587 23.6838 23.1213 23.1212C23.6839 22.5586 24 21.7955 24 20.9999V15.9999C23.9986 14.597 23.6282 13.2191 22.9262 12.0045C22.2241 10.79 21.215 9.78134 20 9.07989Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2212">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
