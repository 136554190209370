import React from "react";

export const VideoCamera = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2222)">
        <path
          d="M14.021 6.10507L9.793 1.87807C9.51528 1.5984 9.18478 1.37669 8.82068 1.2258C8.45658 1.0749 8.06613 0.997835 7.672 0.999069H1C0.734784 0.999069 0.48043 1.10443 0.292893 1.29196C0.105357 1.4795 0 1.73385 0 1.99907C0 2.26428 0.105357 2.51864 0.292893 2.70618C0.48043 2.89371 0.734784 2.99907 1 2.99907H7.672C7.93706 2.99978 8.19114 3.10507 8.379 3.29207L11.086 5.99907H5C3.67441 6.00066 2.40356 6.52795 1.46622 7.46529C0.528882 8.40263 0.00158786 9.67347 0 10.9991L0 18.9991C0.00158786 20.3247 0.528882 21.5955 1.46622 22.5329C2.40356 23.4702 3.67441 23.9975 5 23.9991H13C14.3256 23.9975 15.5964 23.4702 16.5338 22.5329C17.4711 21.5955 17.9984 20.3247 18 18.9991V10.9991C17.9984 9.85078 17.6022 8.73793 16.8778 7.84695C16.1534 6.95598 15.1448 6.34101 14.021 6.10507Z"
          fill="#374957"
        />
        <path
          d="M22.8999 8.95587C22.5688 8.78809 22.1974 8.71639 21.8276 8.74891C21.4579 8.78143 21.1046 8.91687 20.8079 9.13987L20.0079 9.73987V20.2619L20.8079 20.8619C21.1051 21.0847 21.4584 21.2204 21.8283 21.2538C22.1982 21.2871 22.5701 21.2168 22.9024 21.0507C23.2346 20.8846 23.514 20.6293 23.7092 20.3133C23.9045 19.9974 24.0079 19.6333 24.0079 19.2619V10.7439C24.0089 10.372 23.9056 10.0074 23.7098 9.69133C23.5139 9.37526 23.2333 9.12046 22.8999 8.95587Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2222">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
