import React from "react";

export const ArrowsMobileVersion = () => {
  return (
    <svg
      width="380"
      height="220"
      viewBox="0 0 400 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M381.809 219.517C351.753 219.514 323.497 208.271 302.247 187.86C280.996 167.448 269.295 140.311 269.298 111.448L269.311 0.0422363L301.849 50.7746L283.266 50.7726L283.259 110.582C283.254 163.251 327.869 206.104 382.715 206.11L470.991 206.12L470.99 219.527L381.809 219.517L381.809 219.517Z"
        fill="#EFC059"
      />
      <path
        d="M61.7929 219.483L61.7944 206.075L249.536 206.096L249.553 50.7695L230.97 50.7675L263.52 0.0417599L263.496 219.505L61.7929 219.483Z"
        fill="#3D5060"
      />
      <rect
        x="-118.999"
        y="206.168"
        width="195.913"
        height="13.1895"
        fill="#3D5060"
      />
    </svg>
  );
};
