import React from "react";

export const NavBarLogo = () => {
  return (
    <svg
      className="cursor-pointer"
      width="78"
      height="60"
      viewBox="0 0 78 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9997 49.0451C23.7027 48.5951 23.3295 48.2518 22.8803 48.015C22.4312 47.7789 21.8787 47.6604 21.2243 47.6604C20.2342 47.6604 19.351 47.9316 18.5743 48.4731C17.7976 49.015 17.1274 49.82 16.564 50.8879C16.4417 49.9115 16.065 49.1292 15.433 48.5417C14.8008 47.9545 13.99 47.6604 13.0003 47.6604C12.0556 47.6604 11.1878 47.9238 10.396 48.4501C9.60418 48.9765 8.9568 49.728 8.45422 50.7048V47.8439H7.38053V59.7692H8.52275V52.3984C8.84258 51.3011 9.3796 50.4131 10.1335 49.7344C10.8873 49.056 11.7516 48.717 12.7261 48.717C13.6856 48.717 14.3938 49.0716 14.8507 49.7811C15.3076 50.4905 15.536 51.6079 15.536 53.1333V59.7688H16.6782V52.4238C16.9826 51.3411 17.5122 50.4524 18.2661 49.7581C19.02 49.0638 19.8842 48.717 20.8588 48.717C21.8182 48.717 22.5299 49.0758 22.9949 49.7924C23.4592 50.5093 23.6915 51.6231 23.6915 53.1333V59.7688H24.8337V52.9244C24.8337 52.1007 24.7687 51.3605 24.6397 50.7044C24.51 50.0483 24.2971 49.4947 24.0001 49.0447L23.9997 49.0451Z"
        fill="#EFC059"
      />
      <path
        d="M36.2556 48.5074C35.3875 47.9425 34.3898 47.6604 33.263 47.6604C32.1665 47.6604 31.1761 47.939 30.2932 48.4961C29.4096 49.0532 28.7169 49.7896 28.2144 50.7051C27.7118 51.6207 27.4605 52.643 27.4605 53.7724C27.4605 54.6116 27.6088 55.4095 27.9061 56.1649C28.2031 56.9207 28.6143 57.5845 29.1397 58.1565C29.6652 58.7288 30.2855 59.1792 31.0017 59.5072C31.7173 59.8353 32.494 59.9996 33.3319 59.9996C34.0629 59.9996 34.7595 59.8734 35.422 59.6203C36.0845 59.3676 36.6633 59.0194 37.1582 58.575C37.653 58.1307 38.015 57.6252 38.2434 57.0585L37.2383 56.7828C36.949 57.4569 36.4387 58.0087 35.7077 58.4375C34.9767 58.8663 34.2 59.0806 33.3776 59.0806C32.5552 59.0806 31.7974 58.8713 31.1047 58.452C30.4113 58.0324 29.8444 57.4573 29.4026 56.7255C28.9608 55.9938 28.7096 55.1779 28.6488 54.2783H39.0658C39.0806 54.2171 39.0887 54.1411 39.0887 54.0499V53.7749C39.0887 52.6762 38.8335 51.6645 38.3236 50.7412C37.8129 49.8178 37.1241 49.0734 36.256 48.5085L36.2556 48.5074ZM28.6027 53.3814C28.6635 52.4517 28.9071 51.6285 29.3337 50.9119C29.7597 50.1954 30.3161 49.6312 31.0014 49.2201C31.6867 48.8086 32.448 48.6025 33.2858 48.6025C34.1237 48.6025 34.8885 48.8086 35.5815 49.2201C36.2742 49.6312 36.8341 50.1954 37.2608 50.9119C37.6867 51.6285 37.9306 52.4517 37.9918 53.3814H28.6027Z"
        fill="#EFC059"
      />
      <path
        d="M52.018 58.253V42.996H50.8757V50.4637C50.4189 49.698 49.7905 49.0391 48.9913 48.4876C48.1917 47.9361 47.274 47.6604 46.2383 47.6604C45.1875 47.6604 44.2508 47.9467 43.4284 48.5191C42.606 49.091 41.9587 49.8429 41.4867 50.774C41.0143 51.7048 40.7785 52.7271 40.7785 53.8417C40.7785 54.6353 40.9191 55.3985 41.2013 56.1313C41.4828 56.8634 41.8789 57.5198 42.3892 58.0996C42.8992 58.68 43.5047 59.1413 44.2052 59.4846C44.9056 59.8275 45.6672 59.9996 46.4896 59.9996C47.3577 59.9996 48.2029 59.7621 49.0253 59.287C49.8477 58.8122 50.5021 58.1766 50.99 57.3799V58.7125C50.99 58.9579 51.0813 59.1876 51.2641 59.4019C51.4469 59.6164 51.6753 59.7465 51.9491 59.7925C52.071 59.8073 52.2534 59.7999 52.4977 59.7695V58.8274C52.3452 58.8122 52.2267 58.7585 52.1434 58.6666C52.0598 58.5747 52.018 58.4372 52.018 58.253V58.253ZM50.8757 55.763C50.8301 56.19 50.6663 56.5979 50.3848 56.9868C50.1026 57.3756 49.7525 57.7189 49.3339 58.0161C48.915 58.3134 48.4736 58.546 48.0089 58.7136C47.544 58.8819 47.1064 58.9653 46.6952 58.9653C46.025 58.9653 45.4047 58.8324 44.8336 58.5651C44.2624 58.2982 43.7599 57.9246 43.3258 57.4442C42.8918 56.9638 42.5526 56.4148 42.3091 55.7972C42.0652 55.18 41.9435 54.5356 41.9435 53.8643C41.9435 53.2089 42.05 52.5719 42.2634 51.9544C42.4764 51.3368 42.7849 50.7839 43.1884 50.2957C43.5919 49.8079 44.0716 49.4194 44.6276 49.1296C45.1832 48.8397 45.8039 48.6947 46.4892 48.6947C47.0677 48.6947 47.6543 48.8358 48.2483 49.1179C48.8422 49.4003 49.3754 49.7811 49.8474 50.2615C50.3194 50.7415 50.6617 51.2718 50.8754 51.8515V55.763H50.8757Z"
        fill="#EFC059"
      />
      <path
        d="M56.7925 47.8213H55.6502V59.7695H56.7925V47.8213Z"
        fill="#EFC059"
      />
      <path
        d="M69.2993 58.6673C69.2157 58.5758 69.1738 58.439 69.1738 58.2555V52.3517C69.1738 50.9024 68.7626 49.7584 67.9402 48.9192C67.1178 48.0804 65.9984 47.6604 64.5821 47.6604C63.8658 47.6604 63.1464 47.7948 62.4231 48.0627C61.6995 48.331 60.9498 48.7255 60.1731 49.2462L60.6525 50.0734C61.3224 49.6135 61.9778 49.2614 62.6175 49.0165C63.2568 48.7718 63.8736 48.6488 64.4679 48.6488C65.5792 48.6488 66.4515 48.9765 67.0834 49.6312C67.7153 50.2866 68.0313 51.1625 68.0313 52.2591V53.3822C67.5132 53.1983 66.927 53.053 66.2722 52.9459C65.6175 52.8388 64.9852 52.7851 64.3761 52.7851C63.4012 52.7851 62.5405 52.9339 61.7944 53.2319C61.0482 53.5299 60.4616 53.9421 60.0353 54.4685C59.6087 54.9955 59.396 55.6177 59.396 56.3353C59.396 57.0225 59.5672 57.6446 59.9099 58.2018C60.2529 58.7592 60.7242 59.1983 61.3262 59.5192C61.9276 59.8395 62.6017 60 63.3478 60C64.2921 60 65.1943 59.8081 66.055 59.4256C66.915 59.0427 67.6197 58.5061 68.1683 57.8168V58.7589C68.1831 59.0042 68.2709 59.2262 68.4309 59.4256C68.5908 59.6249 68.8153 59.7469 69.1049 59.7932H69.333C69.4244 59.7932 69.5386 59.7854 69.6761 59.7702V58.8282C69.5081 58.813 69.3826 58.7596 69.2989 58.668L69.2993 58.6673ZM68.0316 56.243C68.0316 56.3961 67.9936 56.5636 67.9174 56.7468C67.8411 56.9302 67.7269 57.1208 67.5747 57.3187C67.2092 57.8377 66.6644 58.2608 65.9415 58.5885C65.2179 58.9169 64.4299 59.0806 63.5769 59.0806C62.9679 59.0806 62.4308 58.9512 61.9666 58.6917C61.5019 58.4326 61.14 58.0893 60.8813 57.662C60.6223 57.2349 60.4929 56.7697 60.4929 56.2664C60.4929 55.4579 60.8623 54.817 61.6011 54.3444C62.3395 53.8714 63.3028 53.6346 64.4907 53.6346C65.1149 53.6346 65.7247 53.6879 66.3183 53.7947C66.9122 53.9018 67.4833 54.0619 68.0316 54.2751V56.2427V56.243Z"
        fill="#EFC059"
      />
      <path
        d="M56.1739 45.4585C56.9111 45.4585 57.5087 44.8574 57.5087 44.1159C57.5087 43.3744 56.9111 42.7733 56.1739 42.7733C55.4367 42.7733 54.8391 43.3744 54.8391 44.1159C54.8391 44.8574 55.4367 45.4585 56.1739 45.4585Z"
        fill="#EFC059"
      />
      <path
        d="M42.6904 3.26843C43.1603 1.81096 44.5102 0.823639 46.0334 0.823639H42.5185C40.9953 0.823639 39.6454 1.81061 39.1755 3.26843L35.1847 15.648L36.8928 21.2527L42.6904 3.26843Z"
        fill="#3D5060"
      />
      <path
        d="M66.5868 3.28082C67.0528 1.81698 68.4059 0.824005 69.934 0.824005H66.4191C64.891 0.824005 63.5379 1.81698 63.0719 3.28082L59.0741 15.8371L60.7829 21.5093L66.5864 3.28082H66.5868Z"
        fill="#3D5060"
      />
      <path
        d="M46.8804 23.2599L50.938 36.7614H54.4525L48.682 17.559L46.8804 23.2599Z"
        fill="#3D5060"
      />
      <path
        d="M51.7934 3.33384L57.2687 21.5093L59.0748 15.8371L55.3083 3.33384C54.8591 1.84314 53.4934 0.824005 51.9449 0.824005H48.43C49.9785 0.824005 51.3446 1.84349 51.7934 3.33384Z"
        fill="#EFC059"
      />
      <path
        d="M73.9828 0.824005H70.4679C72.8486 0.824005 74.5405 3.15497 73.8155 5.43575L63.8542 36.7614H67.3691L77.3304 5.43575C78.0558 3.15497 76.3639 0.824005 73.9828 0.824005Z"
        fill="#EFC059"
      />
      <path
        d="M39.0989 36.7614H42.6138L46.8804 23.2599L45.1674 17.559L39.0989 36.7614Z"
        fill="#EFC059"
      />
      <path
        d="M35.1847 15.648L33.2489 9.29489C33.3625 9.92235 33.4261 10.5714 33.4261 11.2416C33.4261 13.2311 33.0078 15.0848 32.1784 16.8106C32.1492 16.8711 32.1116 16.934 32.0803 16.9951L33.3779 21.253L35.1847 15.6483V15.648Z"
        fill="#95875D"
      />
      <path
        d="M70.4682 0.824005H69.934C68.4059 0.824005 67.0528 1.81698 66.5868 3.28082L60.7832 21.5093L59.0745 15.8371L57.2683 21.5093L51.793 3.33384C51.3439 1.84314 49.9781 0.824005 48.4296 0.824005H46.0334C44.5102 0.824005 43.1603 1.81097 42.6904 3.2688L36.8928 21.253L35.1847 15.6483L33.3779 21.253L32.0803 16.9951C31.22 18.6827 29.6339 20.5807 27.3009 22.6932L25.6916 24.162L26.7052 27.3636L29.6968 36.7617H39.0985L45.1667 17.5593L46.8801 23.2602L48.6816 17.5593L54.4521 36.7617H63.8539L73.8155 5.43575C74.5409 3.15497 72.849 0.824005 70.4679 0.824005H70.4682Z"
        fill="#F3F8F1"
      />
      <path
        d="M9.48644 2.23342C11.6792 0.987687 14.2588 0.270439 17.2146 0.0671774C16.6452 0.0258181 16.0642 0.000366211 15.4657 0.000366211C11.7565 0.000366211 8.59199 0.744834 5.9719 2.23342C3.35111 3.72235 1.36083 5.75002 0 8.31748L9.08543 12.8861C9.32477 12.3891 9.61472 11.9465 9.95176 11.5541L3.51489 8.31748C4.87572 5.75002 6.866 3.72235 9.48679 2.23377L9.48644 2.23342Z"
        fill="#3D5060"
      />
      <path
        d="M18.5213 17.3013C19.6442 16.2408 20.3921 15.3333 20.7671 14.5807C21.1413 13.8281 21.3287 13.0911 21.3287 12.3728C21.3287 11.4834 21.0306 10.7902 20.4356 10.2935C19.8399 9.79755 18.9806 9.54904 17.8577 9.54904C17.2486 9.54904 16.6621 9.64343 16.0959 9.82548C16.4108 9.9439 16.687 10.0991 16.9207 10.2935C17.5158 10.7898 17.8138 11.4834 17.8138 12.3728C17.8138 13.0915 17.6265 13.8281 17.2522 14.5807C16.8772 15.3333 16.1293 16.2408 15.0064 17.3013L2.09291 29.3146V36.7589H5.60744V29.3146L18.5213 17.3013Z"
        fill="#3D5060"
      />
      <path
        d="M29.6964 36.7589L26.7059 27.364H23.191L26.1815 36.7589H29.6964Z"
        fill="#EFC059"
      />
      <path
        d="M23.191 27.364H26.7059L26.7056 27.3633L25.692 24.162L25.6927 24.1641L22.9675 26.658L23.1907 27.3633"
        fill="#3D5060"
      />
      <path
        d="M11.2803 10.4222C10.7742 10.7311 10.3327 11.1101 9.95141 11.5541L12.6 12.8861C13.1106 11.8263 13.842 11.0047 14.7948 10.4225C15.2148 10.1659 15.6488 9.969 16.0955 9.82548C15.6081 9.64237 15.0246 9.54904 14.3425 9.54904C13.253 9.54904 12.2323 9.83997 11.2799 10.4222H11.2803Z"
        fill="#EFC059"
      />
      <path
        d="M27.3006 22.6925C29.7161 20.5026 31.3409 18.5424 32.1752 16.8142C33.0085 15.0859 33.4254 13.2286 33.4254 11.2434C33.4254 11.2183 33.4229 11.1946 33.4229 11.1695C33.4208 10.8687 33.4078 10.5714 33.3814 10.279C33.379 10.2511 33.3751 10.2235 33.3723 10.1956C33.3424 9.89089 33.3024 9.59006 33.2489 9.29489L33.2507 9.3009C32.9924 7.8565 32.443 6.53407 31.588 5.33889C30.3628 3.62796 28.6611 2.31047 26.4838 1.38571C24.3058 0.461669 21.8049 0 18.9806 0C18.3758 0 17.7906 0.0272194 17.2146 0.0668112C19.3324 0.220937 21.2524 0.657507 22.9689 1.38571C25.1462 2.31047 26.8483 3.62796 28.0731 5.33889C28.9282 6.53407 29.4775 7.8565 29.7358 9.3009L29.734 9.29489C29.7875 9.59006 29.8275 9.89089 29.8574 10.1956C29.8599 10.2235 29.8641 10.2507 29.8665 10.279C29.8929 10.5714 29.9059 10.8687 29.908 11.1695C29.908 11.1942 29.9105 11.2183 29.9105 11.2434C29.9105 13.2286 29.4933 15.0859 28.6603 16.8142C27.826 18.5424 26.2012 20.5026 23.7857 22.6925L18.6815 27.3643H22.1964L25.6927 24.1645L25.692 24.1623L27.3006 22.6928V22.6925Z"
        fill="#EFC059"
      />
      <path
        d="M22.1778 24.1641L22.1771 24.162L23.3439 23.0969L23.7857 22.6925C26.2012 20.5026 27.826 18.5424 28.6603 16.8142C29.4936 15.0859 29.9105 13.2286 29.9105 11.2434C29.9105 11.2183 29.908 11.1946 29.908 11.1695C29.9059 10.8687 29.8929 10.5714 29.8665 10.279C29.8641 10.2511 29.8602 10.2235 29.8574 10.1956C29.8275 9.89089 29.7875 9.59006 29.734 9.29489L29.7358 9.3009C29.4775 7.85651 28.9282 6.53407 28.0731 5.33889C26.8479 3.62796 25.1462 2.31047 22.9689 1.38572C21.2524 0.657865 19.3324 0.221295 17.2146 0.0671692C14.2588 0.270431 11.6795 0.987679 9.48679 2.23341C6.866 3.72199 4.87572 5.75001 3.51489 8.31712L9.95176 11.5537C10.3331 11.1098 10.7745 10.7312 11.2806 10.4218C12.2327 9.83963 13.2537 9.54871 14.3432 9.54871C15.0254 9.54871 15.6088 9.64167 16.0962 9.82514C16.6624 9.64309 17.249 9.54871 17.8581 9.54871C18.981 9.54871 19.8403 9.79721 20.436 10.2932C21.031 10.7895 21.329 11.483 21.329 12.3725C21.329 13.0911 21.1417 13.8278 20.7674 14.5804C20.3924 15.333 19.6445 16.2404 18.5216 17.3009L5.60744 29.3146V36.7589H26.1815L23.191 27.364H18.6815L22.1778 24.1641Z"
        fill="#F3F8F1"
      />
    </svg>
  );
};
