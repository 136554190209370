import React from "react";

export const ELearning = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2066)">
        <path
          d="M4 7.9381V3.1731C4 1.9731 4.634 0.00610352 7.673 0.00610352C8.06903 0.010201 8.4643 0.0416088 8.856 0.100104C9.47301 0.279594 10.0153 0.65404 10.4018 1.16742C10.7883 1.6808 10.9981 2.30551 11 2.9481V10.8731C11.6559 11.0425 12.3441 11.0425 13 10.8731V2.9481C13.0019 2.30551 13.2117 1.6808 13.5982 1.16742C13.9847 0.65404 14.527 0.279594 15.144 0.100104C15.5358 0.0429477 15.9311 0.0128764 16.327 0.0101035C19.366 0.00610352 20 1.9731 20 3.1731V7.9381C19.9976 8.80644 19.7136 9.65057 19.1907 10.3438C18.6678 11.0371 17.9342 11.5421 17.1 11.7831L13.649 12.7701C12.5705 13.0775 11.4275 13.0775 10.349 12.7701L6.9 11.7831C6.06577 11.5421 5.33218 11.0371 4.8093 10.3438C4.28642 9.65057 4.00245 8.80644 4 7.9381ZM22 4.0261V7.9381C21.9961 9.24104 21.5697 10.5076 20.7848 11.5476C19.9999 12.5876 18.8989 13.345 17.647 13.7061L14.198 14.6921C12.762 15.1022 11.24 15.1022 9.804 14.6921L6.352 13.7051C5.10053 13.3437 3.99994 12.5864 3.21529 11.5466C2.43065 10.5068 2.00423 9.2407 2 7.9381V4.0261C1.38121 4.48796 0.878367 5.08747 0.531247 5.77719C0.184127 6.46692 0.00224946 7.22796 0 8.0001L0 15.0001C0.00158786 16.3257 0.528882 17.5965 1.46622 18.5339C2.40356 19.4712 3.67441 19.9985 5 20.0001H11V22.0001H8C7.73478 22.0001 7.48043 22.1055 7.29289 22.293C7.10536 22.4805 7 22.7349 7 23.0001C7 23.2653 7.10536 23.5197 7.29289 23.7072C7.48043 23.8947 7.73478 24.0001 8 24.0001H16C16.2652 24.0001 16.5196 23.8947 16.7071 23.7072C16.8946 23.5197 17 23.2653 17 23.0001C17 22.7349 16.8946 22.4805 16.7071 22.293C16.5196 22.1055 16.2652 22.0001 16 22.0001H13V20.0001H19C20.3256 19.9985 21.5964 19.4712 22.5338 18.5339C23.4711 17.5965 23.9984 16.3257 24 15.0001V8.0001C23.9978 7.22796 23.8159 6.46692 23.4688 5.77719C23.1216 5.08747 22.6188 4.48796 22 4.0261Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2066">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
