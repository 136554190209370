import React from "react";

import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";

export const SliderComponent = () => {
  let settings = {
    centerMode: false,
    dots: true,
    infinite: false,
    slidesToShow: 2.8,
    arrows: false,
    appendDots: (dots) => (
      <div
        style={{
          paddingBottom: "55px",
        }}
      >
        <ul className="xxs:-mt-32 md:-mt-4 md2:-mt-10"> {dots} </ul>
      </div>
    ),

    responsive: [
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 210,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {/* First card => */}
        <div>
          <div className="flex flex-col flex-start w-full 5xs:h-[457px] 5xs:w-[330px] xxs:h-[550px] md:h-[550px] md2:w-[475px] isolate md:mb-56">
            {/* Gray card */}
            <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
            {/* Yellow card  */}
            <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
            {/* Beige card */}
            <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] rounded-tl rounded-tr-[50px]">
              <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl sm:text-5xl md:text-4xl md2:text-6xl">
                  Wissen
                </h2>
              </div>

              {/* card content  */}
              <div className="5xs:py-24 xxs:py-16 md2:py-32 5xs:px-10 font-Raleway font-thin leading-6 text-lg md:text-2xl md2:text-xl">
                <p className="5xs:mb-20 xxs:mb-20 md:mb-40 md2:mb-32">
                  Wir bringen komplexe Dinge, essentielles Know-How und wichtige
                  Botschaften charmant und prägnant auf den Punkt. <br />
                  <br />
                  Oder anders: wir sorgen dafür, dass Wissen nachhaltig haften
                  bleibt. Und oft braucht es dazu eine Prise Witz.
                </p>
                <h2 className="font-Montserrat tracking-tight font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                  + Witz
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* second card  =>  */}
        <div>
          <div className="flex flex-col flex-start w-full 5xs:h-[457px] md:h-[550px] md2:w-[475px] xxs:h-[550px] isolate">
            {/* Gray card */}
            <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
            {/* Yellow card  */}
            <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
            {/* Beige card */}
            <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] rounded-tl rounded-tr-[50px]">
              <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl md:text-4xl xxs:text-4xl sm:text-5xl md2:text-6xl">
                  Content
                </h2>
              </div>

              {/* card content  */}
              <div className="5xs:py-14 sm:py-16 md2:py-16 5xs:px-10 font-Raleway font-thin text-lg md:text-2xl md2:text-xl leading-5">
                <p className="5xs:mb-14 xxs:mb-12 md2:mb-20">
                  Guter Content ist wichtig für jede Art von interner und
                  externer Kommunikation - soweit so klar. <br />
                  <br />
                  Wir gehen aber gerne noch einen Schritt weiter und würzen
                  Projekte mit unserer geheimen Zutat: Daten. <br />
                  <br />
                  Das Ergebnis: Ein gut ausbalanciertes Gericht, das nicht nur
                  gut schmeckt sondern auch gut funktioniert.
                </p>
                <h2 className="font-Montserrat tracking-tight font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                  + Daten
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* Third card  */}
        <div>
          <div className="flex flex-col flex-start w-full 5xs:h-[457px] xxs:h-[550px] md:h-[550px] md2:w-[475px] mb-56 isolate ">
            {/* Gray card */}
            <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
            {/* Yellow card  */}
            <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
            {/* Beige card */}
            <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md:h-[672px] md2:w-[475px] md2:h-[675px] rounded-tl rounded-tr-[50px]">
              <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl  md:text-4xl sm:text-5xl xxs:text-4xl md2:text-6xl">
                  Cybersec
                </h2>
              </div>
              {/* card content  */}
              <div className="5xs:py-14 sm:py-16 md2:py-20 5xs:px-10 font-Raleway font-thin text-lg md:text-2xl leading-5 md2:leading-6 md2:text-xl">
                <p className="5xs:mb-20 xxs:mb-8 sm:mb-14 md:mb-12 md2:mb-32">
                  Cybersecurity ist ein Teamsport. <br />
                  <br />
                  Wir erstellen für große Brands firmeninterne
                  Awareness-Kampagnen für mehr Cybersecurity im Unternehmen.
                  <br />
                  <br />
                  Mit einem Mix aus gutem Storytelling, E-Learnings,
                  Offline-Medien, Videos und Events schaffen wir nachhaltiges
                  Engagement unter den Mitarbeiter:innen.
                </p>
                <h2 className="font-Montserrat tracking-tight font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                  + Engage
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth card  */}
        <div>
          <div>
            <div className="flex flex-col flex-start w-full 5xs:h-[457px] md:h-[550px] md2:w-[475px] xxs:h-[550px] mb-56 isolate ">
              {/* Gray card */}
              <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
              {/* Yellow card  */}
              <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
              {/* Beige card */}
              <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] rounded-tl rounded-tr-[50px]">
                <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                  <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl md:text-4xl sm:text-5xl xxs:text-4xl md2:text-6xl">
                    Recruiting
                  </h2>
                </div>
                {/* card content  */}
                <div className="5xs:py-14 sm:py-20 md2:py-16 5xs:px-10 font-Raleway font-thin text-lg md:text-2xl leading-5 md2:text-xl">
                  <p className="5xs:mb-20 xxs:mb-12 sm:mb-16 md2:mb-20">
                    “Hey! Arbeite bei uns!” <br />
                    <br />
                    Naja... superschwellige Ansagen funktionieren nicht wirklich
                    gut - schon gar nicht bei grassierendem Fachkräftemangel.
                    <br />
                    <br />
                    Mit Bildschirm, Charme, aber ohne Melone helfen wir Ihnen,
                    Ihre potenziellen Mitarbeiter:innen unwiderstehlich
                    anzusprechen.
                  </p>
                  <h2 className="font-Montserrat tracking-tight font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                    + Charme
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fifth card  */}
        <div>
          <div>
            <div className="flex flex-col flex-start w-full 5xs:h-[457px] md:h-[550px] md2:w-[475px] xxs:h-[550px] isolate">
              {/* Gray card */}
              <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
              {/* Yellow card  */}
              <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
              {/* Beige card */}
              <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] rounded-tl	rounded-tr-[50px]">
                <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                  <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl md:text-4xl sm:text-5xl xxs:text-4xl md2:text-6xl">
                    B2B
                  </h2>
                </div>
                {/* card content  */}
                <div className="5xs:py-16 xxs:py-16 sm:py-16 md2:py-24 5xs:px-10 xxs:py-14 font-Raleway font-thin text-lg md:text-2xl md2:text-xl leading-5">
                  <p className="5xs:mb-24 xxs:mb-20 sm:mb-20 md2:mb-[75px]">
                    <br />
                    Manche behaupten, B2B sei ein trockenes Thema. Können wir
                    gar nicht nachvollziehen - wir bei 2WMEDIA lieben B2B.
                    <br />
                    <br />
                    Manchmal braucht es einfach die richtige Verpackung oder
                    einen Schubs in die richtige Richtung - und aus einem drögen
                    Kommuniqué wird sexy Storytelling.
                  </p>
                  <h2 className="font-Montserrat tracking-tight font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                    + Sexy
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Last card  */}
        <div>
          <div>
            <div className="flex flex-col flex-start w-full 5xs:h-[457px] md:h-[550px] md2:w-[475px] xxs:h-[550px] isolate">
              {/* Gray card */}
              <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 5xs:h-[562px] 4xs:w-[350px] 4xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px]  md:h-[672px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
              {/* Yellow card  */}
              <div className="absolute bg-[#EFC059] z-10 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px] md:h-[672px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
              {/* Beige card */}
              <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[350px] 5xs:h-[562px] xxs:w-[340px] xxs:h-[542px] sm:w-[495px] md:w-[495px] md2:w-[475px] md2:h-[675px]  md:h-[672px] rounded-tl	rounded-tr-[50px]">
                <div className="5xs:pt-10 md2:pt-14 sm:pt-16 text-right 5xs:pr-10 md2:pr-14 sm:pr-16">
                  <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-4xl 4xs:text-4xl xxs:text-3xl md:text-4xl xxs:text-4xl sm:text-5xl md2:text-6xl">
                    Kunst
                  </h2>
                </div>
                {/* card content  */}
                <div className="5xs:py-20 5xs:px-8 sm:py-16 md2:py-20 font-Raleway font-thin text-lg md:text-2xl md2:text-xl leading-5">
                  <p className="5xs:mb-24 xxs:mb-20 sm:mb-24 md:mb-36 md2:mb-[117px]">
                    <br />
                    Man sagt: Die Kunst ist lang, und kurz ist unser Leben.
                    <br /> <br />
                    Da können wir nur den Kopf schütteln. Wir kreieren Schönes
                    UND berücksichtigen Ihre Deadline.
                    <br /> <br />
                    <span>Schließlich haben wir nicht ewig Zeit.</span>
                  </p>
                  <h2 className="font-Montserrat tracking-tighter font-light text-yellow-600 uppercase 5xs:text-5xl md:text-4xl xxs:text-3xl sm:text-5xl md2:text-6xl">
                    + Deadline
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};
