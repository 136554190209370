import React from "react";

export const LightbulbAndPentagonMobile = () => {
  return (
    <svg
      width="181"
      height="59"
      viewBox="0 0 181 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="24" y="29.9722" width="11" height="27" rx="5.5" fill="#EFC059" />
      <rect
        x="20"
        y="23.9722"
        width="19"
        height="30"
        rx="8"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle cx="29.5" cy="24.4722" r="20.5" fill="#EFC059" />
      <path
        d="M89.684 37.8242V22.9202H91.304V37.8242H89.684ZM82.844 31.1642V29.6162H98.144V31.1642H82.844Z"
        fill="#F3F8F1"
      />
      <path
        d="M123 21.9722L152 0.972168L181 21.9722V58.9722H123V21.9722Z"
        fill="#3D5060"
      />
    </svg>
  );
};
