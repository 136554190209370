import React from "react";

export const EllipseAndUserDesktop = () => {
  return (
    <svg
      width="229"
      height="81"
      viewBox="0 0 229 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="52.9655"
        cy="40.9099"
        rx="32.4138"
        ry="32.4138"
        fill="#EFC059"
      />
      <circle
        cx="35.0345"
        cy="40.9099"
        r="33.4138"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <path
        d="M115.435 50.4992V31.0412H117.55V50.4992H115.435ZM106.505 41.8042V39.7832H126.48V41.8042H106.505Z"
        fill="#F3F8F1"
      />
      <path
        d="M220.724 72.634H188.31H155.897C155.897 54.7324 170.409 40.2202 188.31 40.2202C206.212 40.2202 220.724 54.7324 220.724 72.634Z"
        fill="#3D5060"
      />
      <circle
        cx="188.31"
        cy="27.1168"
        r="19.6207"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
    </svg>
  );
};
