import React from "react";

export const IconsTablet = () => {
  return (
    <svg
      width="263"
      height="305"
      viewBox="0 0 263 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="81.1" cy="266.143" r="36.3" fill="#EFC059" />
      <ellipse
        cx="80.6477"
        cy="263.717"
        rx="33.492"
        ry="26.0291"
        fill="#222222"
      />
      <ellipse
        cx="80.6474"
        cy="258.639"
        rx="34.9171"
        ry="26.0291"
        fill="#EFC059"
      />
      <rect x="150.4" y="229.843" width="74.8" height="72.6" fill="#3D5060" />
      <mask
        id="path-5-outside-1_89_3022"
        maskUnits="userSpaceOnUse"
        x="148.878"
        y="226.8"
        width="54"
        height="78"
        fill="black"
      >
        <rect fill="white" x="148.878" y="226.8" width="54" height="78" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.621 228.8H150.878V302.444H182.621L182.621 277.049C192.438 277.049 200.397 269.091 200.397 259.273C200.397 249.456 192.438 241.498 182.621 241.497V228.8Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.621 228.8H150.878V302.444H182.621L182.621 277.049C192.438 277.049 200.397 269.091 200.397 259.273C200.397 249.456 192.438 241.498 182.621 241.497V228.8Z"
        fill="#3D5060"
      />
      <path
        d="M150.878 228.8V226.8H148.878V228.8H150.878ZM182.621 228.8H184.621V226.8H182.621V228.8ZM150.878 302.444H148.878V304.444H150.878V302.444ZM182.621 302.444V304.444H184.621V302.444H182.621ZM182.621 277.049V275.049H180.621L180.621 277.049H182.621ZM182.621 241.497H180.621V243.497L182.621 243.497L182.621 241.497ZM150.878 230.8H182.621V226.8H150.878V230.8ZM152.878 302.444V228.8H148.878V302.444H152.878ZM182.621 300.444H150.878V304.444H182.621V300.444ZM180.621 277.049L180.621 302.444H184.621L184.621 277.049H180.621ZM198.397 259.273C198.397 267.986 191.334 275.049 182.621 275.049V279.049C193.543 279.049 202.397 270.195 202.397 259.273H198.397ZM182.621 243.497C191.334 243.498 198.397 250.561 198.397 259.273H202.397C202.397 248.352 193.543 239.498 182.621 239.497L182.621 243.497ZM180.621 228.8V241.497H184.621V228.8H180.621Z"
        fill="#222222"
        mask="url(#path-5-outside-1_89_3022)"
      />
      <path
        d="M0.799988 137.083L37.1 110L73.4 137.083V184.8H0.799988V137.083Z"
        fill="#3D5060"
      />
      <rect
        x="224.446"
        y="37.4128"
        width="13.9669"
        height="34.2823"
        rx="6.98343"
        fill="#EFC059"
      />
      <rect
        x="219.637"
        y="30.0642"
        width="23.5851"
        height="37.552"
        rx="8"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle cx="231.429" cy="30.4294" r="26.0291" fill="#EFC059" />
      <circle cx="140.5" cy="155.1" r="29.7" fill="#EFC059" />
      <circle
        cx="125.1"
        cy="155.1"
        r="30.7"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <path
        d="M260.4 185.8H261.4V184.8C261.4 167.272 247.689 153 230.7 153C213.711 153 200 167.272 200 184.8V185.8H201H230.7H260.4Z"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle
        cx="229.6"
        cy="143"
        r="18.6"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <rect
        x="106.4"
        y="0.00012207"
        width="72.6"
        height="72.6"
        fill="#3D5060"
      />
      <rect
        x="7.40002"
        y="0.00012207"
        width="72.6"
        height="19.8"
        fill="#EFC059"
      />
      <rect
        x="7.40002"
        y="26.4003"
        width="46.2"
        height="19.8"
        rx="2"
        fill="#EFC059"
      />
      <rect x="64.6" y="26.4003" width="15.4" height="19.8" fill="#EFC059" />
      <rect
        x="44.8"
        y="52.8002"
        width="35.2"
        height="19.8"
        rx="5"
        fill="#EFC059"
      />
      <rect x="7.40002" y="52.8002" width="30.8" height="19.8" fill="#EFC059" />
    </svg>
  );
};
