import React from "react";

import PageLayout from "../Layout/PageLayout";

const Datenschutz = (props) => {
  return (
    <PageLayout id={props.id}>
      <div className="flex flex-col  5xs:mt-16 xs:mt-40  mb-8 overflow-x-hidden">
        <div className="text-yellow-100 xl:mb-14 3xl:mb-14 5xs:w-full 4xs:w-full 3xs:w-96 xxs:w-full xs:w-full sm:w-full md:w-[1140px] xl:w-[1140px]  2xl:w-[1190px] 3xl:w-[1190px] mx-auto xxs:px-4 5xs:px-4 break-words">
          <h1 className="5xs:text-2xl 4xs:text-4xl xxs:text-4xl xs:text-5xl sm:text-5xl md:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl font-Montserrat font-black text-blue-600 text-left">
            Datenschutz
          </h1>
          <h2 className="text-yellow-500 uppercase 5xs:text-2xl 4xs:text-4xl xxs:text-4xl xs:text-5xl sm:text-5xl md:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl  mb-10 -mt-3 font-Montserrat font-light">
            Privacy
          </h2>
          {/* Main Title No. 1 =>  */}
          <h5 className="text-4xl font-Montserrat font-black  mb-3 text-yellow-500">
            1. Datenschutz auf einen Blick
          </h5>
          {/* Subtitle No. 1 in Datenschutz auf einen Blick =>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Allgemeine Hinweise
          </p>
          <p className="text-xl font-Raleway font-normal leading-7 my-5 mb-16">
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          {/* Subtitle No. 2 in Datenschutz auf einen Blick =>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Datenerfassung auf unserer Website
          </p>
          <p className="text-base 5xs:text-xl xxs:text-2xl font-Raleway font-normal text-yellow-500">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>
          <p className="text-base 5xs:text-xl xxs:text-2xl font-Raleway font-normal text-yellow-500">
            Wie erfassen wir Ihre Daten?
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie unsere Website betreten.
          </p>
          <p className="text-base 5xs:text-xl xxs:text-2xl font-Raleway font-normal text-yellow-500">
            Wofür nutzen wir Ihre Daten?
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <p className="text-base 5xs:text-xl xxs:text-2xl font-Raleway font-normal text-yellow-500">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5 mb-8">
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          {/* Subtitle No. 3 in Datenschutz auf einen Blick =>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Analyse-Tools und Tools von Drittanbietern
          </p>
          <p className="text-xl mb-14 font-Raleway font-normal leading-7 my-5">
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung. Sie können dieser Analyse widersprechen. Über
            die Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>

          {/* Main Title No. 2 =>  */}
          <h5 className="text-4xl font-Montserrat font-black  mb-5 text-yellow-500">
            2. Allgemeine Hinweise und Pflichtinformationen
          </h5>
          {/* Subtitle No.1 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Datenschutz
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-8">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </p>

          {/* Subtitle No.2 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Hinweis zur verantwortlichen Stelle
          </p>
          <p className="text-xl  font-Raleway font-normal leading-7">
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Lockit Network GmbH
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Schleißheimer Straße 181
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            80797 München
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            UStIdNr: DE15569723
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Telefon: 089 / 55 05 68 67
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            E-Mail:{" "}
            <a
              className="text-yellow-500 underline"
              href="mailto:hello@lockitnetwork.com"
            >
              {" "}
              hello@lockitnetwork.com
            </a>
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 mb-10">
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          {/* Subtitle No.3 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          {/* Subtitle No.4 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 mb-10">
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
            Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
            Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
            Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
            sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
            <a
              href="  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html."
              className="text-yellow-500 hover:text-cyan-600 underline break-words"
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
            </a>
          </p>
          {/* Subtitle No.5 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Recht auf Datenübertragbarkeit
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          {/* Subtitle No.6 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            SSL- bzw. TLS-Verschlüsselung
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von „http://“
            auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
            <br /> Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
            die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>

          {/* Subtitle No.7 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Auskunft, Sperrung, Löschung
          </p>
          <p className="text-xl 3xl:mb-14 font-Raleway font-normal leading-7 my-5">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>
          {/* Subtitle No.8 in Allgemeine Hinweise und Pflichtinformationen=>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5">
            Widerspruch gegen Werbe-Mails
          </p>
          <p className="text-xl mb-14 font-Raleway font-normal leading-7 my-5">
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>
          {/* Main Title No. 3 => Datenschutzbeauftragter  */}
          <h5 className="text-4xl font-Montserrat font-black  mb-3 text-yellow-500 break-words">
            3. Datenschutz&shy;beauftragter
          </h5>
          {/* Subtitle No.1 in Datenschutzbeauftragter =>  */}
          <p className="text-3xl font-Montserrat font-light mb-8 text-blue-300 tracking-tighter	uppercase leading-6 my-5 break-words">
            Gesetzlich vorgeschriebener Datenschutzbeauftragter
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten
            bestellt.
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Benedikt Meirer
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Schleißheimer Straße 181
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            80797 München
          </p>
          <p className="text-xl font-Raleway font-normal leading-7">
            Telefon: 089 / 716 742 30
          </p>
          <p className="text-xl font-Raleway font-normal leading-7 mb-36">
            E-Mail:{" "}
            <a
              className="text-yellow-500 underline"
              href="mailto:datenschutz@lockitnetwork.com"
            >
              {" "}
              datenschutz@lockitnetwork.com{" "}
            </a>
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Datenschutz;
