import React from "react";

export const IconsMobile = () => {
  return (
    <svg
      width="120"
      height="140"
      viewBox="0 0 120 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="36.5" cy="120.974" r="16.5" fill="#EFC059" />
      <ellipse
        cx="36.2944"
        cy="119.871"
        rx="15.2236"
        ry="11.8314"
        fill="#222222"
      />
      <ellipse
        cx="36.2943"
        cy="117.563"
        rx="15.8714"
        ry="11.8314"
        fill="#EFC059"
      />
      <rect x="68" y="104.474" width="34" height="33" fill="#3D5060" />
      <mask
        id="path-5-outside-1_60_2280"
        maskUnits="userSpaceOnUse"
        x="66.2173"
        y="102"
        width="27"
        height="38"
        fill="black"
      >
        <rect fill="white" x="66.2173" y="102" width="27" height="38" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.6459 104H68.2173V137.474H82.6459V125.931C87.1083 125.931 90.7259 122.314 90.7259 117.851C90.7259 113.389 87.1083 109.771 82.6459 109.771V104Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6459 104H68.2173V137.474H82.6459V125.931C87.1083 125.931 90.7259 122.314 90.7259 117.851C90.7259 113.389 87.1083 109.771 82.6459 109.771V104Z"
        fill="#3D5060"
      />
      <path
        d="M68.2173 104V102H66.2173V104H68.2173ZM82.6459 104H84.6459V102H82.6459V104ZM68.2173 137.474H66.2173V139.474H68.2173V137.474ZM82.6459 137.474V139.474H84.6459V137.474H82.6459ZM82.6459 125.931V123.931H80.6459V125.931H82.6459ZM82.6459 109.771H80.6459V111.771H82.6459V109.771ZM68.2173 106H82.6459V102H68.2173V106ZM70.2173 137.474V104H66.2173V137.474H70.2173ZM82.6459 135.474H68.2173V139.474H82.6459V135.474ZM80.6459 125.931V137.474H84.6459V125.931H80.6459ZM88.7259 117.851C88.7259 121.209 86.0037 123.931 82.6459 123.931V127.931C88.2129 127.931 92.7259 123.418 92.7259 117.851H88.7259ZM82.6459 111.771C86.0037 111.771 88.7259 114.494 88.7259 117.851H92.7259C92.7259 112.284 88.2129 107.771 82.6459 107.771V111.771ZM80.6459 104V109.771H84.6459V104H80.6459Z"
        fill="#222222"
        mask="url(#path-5-outside-1_60_2280)"
      />
      <path d="M0 62.3103L16.5 50L33 62.3103V84H0V62.3103Z" fill="#3D5060" />
      <rect
        x="101.657"
        y="17.0056"
        width="6.34857"
        height="15.5829"
        rx="3.17429"
        fill="#EFC059"
      />
      <rect
        x="98.9257"
        y="13.1201"
        width="11.8114"
        height="18.16"
        rx="5.90571"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle cx="104.831" cy="13.8314" r="11.8314" fill="#EFC059" />
      <circle cx="63.5" cy="70.5" r="13.5" fill="#EFC059" />
      <circle
        cx="56.5"
        cy="70.5"
        r="14.5"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <path
        d="M118 85H119V84C119 75.7503 112.542 69 104.5 69C96.4579 69 90 75.7503 90 84V85H91H104.5H118Z"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle
        cx="104"
        cy="65"
        r="9"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <rect x="48" width="33" height="33" fill="#3D5060" />
      <rect x="3" width="33" height="9" fill="#EFC059" />
      <rect x="3" y="12" width="21" height="9" rx="2" fill="#EFC059" />
      <rect x="29" y="12" width="7" height="9" fill="#EFC059" />
      <rect x="20" y="24" width="16" height="9" rx="4.5" fill="#EFC059" />
      <rect x="3" y="24" width="14" height="9" fill="#EFC059" />
    </svg>
  );
};
