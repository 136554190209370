import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./components/Pages/Home";
import Impressum from "./components/Pages/Impressum";
import Datenschutz from "./components/Pages/Datenschutz";

import "./styles/App.css";

function App(props) {
  return (
    <Router>
      <Routes>
        <Route exact path="/*" element={<Home id="home" />} id="home" />
        <Route
          exact
          path="/impressum/*"
          element={<Impressum id="impressum" />}
          id="impressum"
        />
        <Route
          exact
          path="/datenschutz/*"
          element={<Datenschutz id="datenschutz" />}
          id="datenschutz"
        />
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
    </Router>
  );
}

export default App;
