import React from "react";

export const ArrowsDesktopVersion2 = () => {
  return (
    <svg
      width="1290"
      height="484"
      viewBox="0 0 990 484"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M993.78 483.02C927.657 483.013 865.494 458.279 818.744 413.373C771.992 368.468 746.249 308.766 746.256 245.268L746.283 0.174561L817.868 111.786L776.985 111.781L776.971 243.361C776.958 359.233 875.111 453.511 995.773 453.524L1189.98 453.545L1189.98 483.041L993.779 483.02L993.78 483.02Z"
        fill="#EFC059"
      />
      <path
        d="M289.744 482.943L289.748 453.447L702.78 453.492L702.817 111.774L661.935 111.77L733.544 0.173122L733.491 482.991L289.744 482.943Z"
        fill="#3D5060"
      />
      <rect
        x="-107.997"
        y="453.651"
        width="431.008"
        height="29.0169"
        fill="#3D5060"
      />
    </svg>
  );
};
