import React from "react";

export const YellowGraySquaresDesktop = () => {
  return (
    <svg
      width="225"
      height="81"
      viewBox="0 0 225 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.220215" width="80" height="22.069" fill="#EFC059" />
      <rect y="29.1858" width="51.0345" height="22.069" rx="2" fill="#EFC059" />
      <rect
        x="62.069"
        y="29.1858"
        width="17.931"
        height="22.069"
        fill="#EFC059"
      />
      <rect
        x="41.3793"
        y="58.1514"
        width="38.6207"
        height="22.069"
        rx="5"
        fill="#EFC059"
      />
      <rect y="58.1514" width="33.1034" height="22.069" fill="#EFC059" />
      <path
        d="M111.435 50.4992V31.0412H113.55V50.4992H111.435ZM102.505 41.8042V39.7832H122.48V41.8042H102.505Z"
        fill="#F3F8F1"
      />
      <rect x="145" y="0.220215" width="80" height="80" fill="#3D5060" />
    </svg>
  );
};
