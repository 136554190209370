import React from "react";

export const NavBarLogoPositive = () => {
  return (
    <svg
      className="cursor-pointer"
      width="78"
      height="60"
      viewBox="0 0 78 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9998 49.0451C23.7028 48.5951 23.3296 48.2518 22.8804 48.015C22.4313 47.7789 21.8788 47.6604 21.2244 47.6604C20.2343 47.6604 19.3511 47.9316 18.5744 48.4731C17.7977 49.015 17.1275 49.82 16.5641 50.8879C16.4418 49.9115 16.065 49.1292 15.4331 48.5417C14.8009 47.9545 13.9901 47.6604 13.0004 47.6604C12.0556 47.6604 11.1879 47.9238 10.3961 48.4501C9.60426 48.9765 8.95688 49.728 8.4543 50.7048V47.8439H7.38062V59.7692H8.52284V52.3984C8.84266 51.3011 9.37968 50.4131 10.1335 49.7344C10.8874 49.056 11.7516 48.717 12.7262 48.717C13.6857 48.717 14.3939 49.0716 14.8508 49.7811C15.3077 50.4905 15.5361 51.6079 15.5361 53.1333V59.7688H16.6783V52.4238C16.9827 51.3411 17.5123 50.4524 18.2662 49.7581C19.0201 49.0638 19.8843 48.717 20.8589 48.717C21.8183 48.717 22.53 49.0758 22.995 49.7924C23.4593 50.5093 23.6916 51.6231 23.6916 53.1333V59.7688H24.8338V52.9244C24.8338 52.1007 24.7688 51.3605 24.6398 50.7044C24.5101 50.0483 24.2971 49.4947 24.0002 49.0447L23.9998 49.0451Z"
        fill="#3D5060"
      />
      <path
        d="M36.2556 48.5074C35.3875 47.9425 34.3897 47.6604 33.263 47.6604C32.1664 47.6604 31.176 47.939 30.2932 48.4961C29.4096 49.0532 28.7169 49.7896 28.2143 50.7051C27.7117 51.6207 27.4604 52.643 27.4604 53.7724C27.4604 54.6116 27.6088 55.4095 27.9061 56.1649C28.2031 56.9207 28.6143 57.5845 29.1397 58.1565C29.6651 58.7288 30.2854 59.1792 31.0017 59.5072C31.7173 59.8353 32.494 59.9996 33.3318 59.9996C34.0629 59.9996 34.7594 59.8734 35.4219 59.6203C36.0844 59.3676 36.6633 59.0194 37.1581 58.575C37.653 58.1307 38.015 57.6252 38.2434 57.0585L37.2383 56.7828C36.949 57.4569 36.4387 58.0087 35.7077 58.4375C34.9766 58.8663 34.1999 59.0806 33.3775 59.0806C32.5551 59.0806 31.7974 58.8713 31.1047 58.452C30.4113 58.0324 29.8444 57.4573 29.4026 56.7255C28.9608 55.9938 28.7095 55.1779 28.6487 54.2783H39.0658C39.0806 54.2171 39.0886 54.1411 39.0886 54.0499V53.7749C39.0886 52.6762 38.8335 51.6645 38.3235 50.7412C37.8129 49.8178 37.124 49.0734 36.2559 48.5085L36.2556 48.5074ZM28.6027 53.3814C28.6635 52.4517 28.907 51.6285 29.3337 50.9119C29.7597 50.1954 30.316 49.6312 31.0014 49.2201C31.6867 48.8086 32.4479 48.6025 33.2858 48.6025C34.1237 48.6025 34.8884 48.8086 35.5815 49.2201C36.2742 49.6312 36.8341 50.1954 37.2607 50.9119C37.6867 51.6285 37.9306 52.4517 37.9918 53.3814H28.6027Z"
        fill="#3D5060"
      />
      <path
        d="M52.0181 58.253V42.996H50.8758V50.4637C50.4189 49.698 49.7905 49.0391 48.9913 48.4876C48.1918 47.9362 47.2741 47.6604 46.2384 47.6604C45.1876 47.6604 44.2509 47.9468 43.4285 48.5191C42.6061 49.0911 41.9587 49.8429 41.4867 50.7741C41.0144 51.7048 40.7786 52.7271 40.7786 53.8417C40.7786 54.6353 40.9191 55.3985 41.2014 56.1313C41.4829 56.8634 41.879 57.5199 42.3893 58.0996C42.8992 58.6801 43.5048 59.1414 44.2052 59.4846C44.9057 59.8275 45.6673 59.9997 46.4897 59.9997C47.3578 59.9997 48.203 59.7621 49.0254 59.287C49.8478 58.8123 50.5022 58.1767 50.9901 57.3799V58.7126C50.9901 58.9579 51.0814 59.1877 51.2642 59.4019C51.4469 59.6165 51.6754 59.7466 51.9492 59.7925C52.0711 59.8074 52.2535 59.7999 52.4978 59.7695V58.8275C52.3453 58.8123 52.2268 58.7585 52.1435 58.6666C52.0599 58.5747 52.0181 58.4372 52.0181 58.253V58.253ZM50.8758 55.763C50.8301 56.19 50.6664 56.5979 50.3849 56.9868C50.1026 57.3756 49.7526 57.7189 49.334 58.0162C48.9151 58.3135 48.4736 58.5461 48.009 58.7136C47.544 58.8819 47.1065 58.9653 46.6953 58.9653C46.0251 58.9653 45.4047 58.8324 44.8336 58.5652C44.2625 58.2983 43.7599 57.9246 43.3259 57.4442C42.8919 56.9638 42.5527 56.4148 42.3091 55.7973C42.0652 55.1801 41.9436 54.5356 41.9436 53.8643C41.9436 53.209 42.0501 52.5719 42.2635 51.9544C42.4764 51.3368 42.785 50.784 43.1885 50.2958C43.592 49.8079 44.0717 49.4195 44.6277 49.1296C45.1833 48.8397 45.804 48.6948 46.4893 48.6948C47.0678 48.6948 47.6544 48.8358 48.2484 49.1179C48.8423 49.4004 49.3755 49.7811 49.8475 50.2615C50.3195 50.7415 50.6618 51.2718 50.8755 51.8515V55.763H50.8758Z"
        fill="#3D5060"
      />
      <path
        d="M56.7926 47.8213H55.6504V59.7695H56.7926V47.8213Z"
        fill="#3D5060"
      />
      <path
        d="M69.2995 58.6673C69.2159 58.5758 69.174 58.439 69.174 58.2555V52.3517C69.174 50.9024 68.7628 49.7584 67.9404 48.9192C67.118 48.0804 65.9987 47.6604 64.5823 47.6604C63.866 47.6604 63.1466 47.7948 62.4233 48.0627C61.6997 48.331 60.95 48.7255 60.1733 49.2462L60.6527 50.0734C61.3226 49.6135 61.978 49.2614 62.6177 49.0165C63.257 48.7718 63.8738 48.6488 64.4681 48.6488C65.5794 48.6488 66.4517 48.9765 67.0836 49.6312C67.7155 50.2866 68.0315 51.1625 68.0315 52.2591V53.3822C67.5134 53.1983 66.9272 53.053 66.2724 52.9459C65.6177 52.8388 64.9854 52.7851 64.3763 52.7851C63.4014 52.7851 62.5407 52.9339 61.7946 53.2319C61.0484 53.5299 60.4618 53.9421 60.0355 54.4685C59.6089 54.9955 59.3962 55.6177 59.3962 56.3353C59.3962 57.0225 59.5674 57.6446 59.9101 58.2018C60.2531 58.7592 60.7244 59.1983 61.3264 59.5192C61.9278 59.8395 62.6019 60 63.348 60C64.2923 60 65.1945 59.8081 66.0552 59.4256C66.9152 59.0427 67.6199 58.5061 68.1685 57.8168V58.7589C68.1833 59.0042 68.2711 59.2262 68.4311 59.4256C68.591 59.6249 68.8156 59.7469 69.1051 59.7932H69.3332C69.4246 59.7932 69.5388 59.7854 69.6763 59.7702V58.8282C69.5083 58.813 69.3828 58.7596 69.2992 58.668L69.2995 58.6673ZM68.0318 56.243C68.0318 56.3961 67.9939 56.5636 67.9176 56.7468C67.8413 56.9302 67.7271 57.1208 67.5749 57.3187C67.2094 57.8377 66.6647 58.2608 65.9417 58.5885C65.2181 58.9169 64.4301 59.0806 63.5771 59.0806C62.9681 59.0806 62.431 58.9512 61.9668 58.6917C61.5021 58.4326 61.1402 58.0893 60.8815 57.662C60.6225 57.2349 60.4931 56.7697 60.4931 56.2664C60.4931 55.4579 60.8625 54.817 61.6013 54.3444C62.3397 53.8714 63.303 53.6346 64.4909 53.6346C65.1151 53.6346 65.7249 53.6879 66.3185 53.7947C66.9124 53.9018 67.4835 54.0619 68.0318 54.2751V56.2427V56.243Z"
        fill="#3D5060"
      />
      <path
        d="M56.1739 45.4585C56.9111 45.4585 57.5088 44.8574 57.5088 44.1159C57.5088 43.3744 56.9111 42.7733 56.1739 42.7733C55.4367 42.7733 54.8391 43.3744 54.8391 44.1159C54.8391 44.8574 55.4367 45.4585 56.1739 45.4585Z"
        fill="#3D5060"
      />
      <path
        d="M42.6905 3.26846C43.1604 1.81099 44.5103 0.823669 46.0335 0.823669H42.5186C40.9954 0.823669 39.6455 1.81064 39.1756 3.26846L35.1848 15.648L36.8929 21.2527L42.6905 3.26846Z"
        fill="#3D5060"
      />
      <path
        d="M66.5869 3.28085C67.0529 1.81701 68.406 0.824036 69.9341 0.824036H66.4192C64.8911 0.824036 63.538 1.81701 63.072 3.28085L59.0742 15.8371L60.783 21.5094L66.5865 3.28085H66.5869Z"
        fill="#3D5060"
      />
      <path
        d="M46.8804 23.2598L50.9379 36.7614H54.4524L48.6819 17.559L46.8804 23.2598Z"
        fill="#3D5060"
      />
      <path
        d="M51.7933 3.33387L57.2686 21.5094L59.0748 15.8371L55.3082 3.33387C54.8591 1.84317 53.4933 0.824036 51.9448 0.824036H48.4299C49.9784 0.824036 51.3445 1.84353 51.7933 3.33387Z"
        fill="#EFC059"
      />
      <path
        d="M73.9828 0.823975H70.4679C72.8487 0.823975 74.5406 3.15494 73.8155 5.43572L63.8542 36.7613H67.3691L77.3304 5.43572C78.0558 3.15494 76.3639 0.823975 73.9828 0.823975Z"
        fill="#EFC059"
      />
      <path
        d="M39.0989 36.7614H42.6138L46.8804 23.2598L45.1674 17.559L39.0989 36.7614Z"
        fill="#EFC059"
      />
      <path
        d="M35.1847 15.648L33.2489 9.29492C33.3624 9.92238 33.426 10.5714 33.426 11.2416C33.426 13.2311 33.0078 15.0849 32.1784 16.8107C32.1492 16.8711 32.1116 16.934 32.0803 16.9952L33.3779 21.2531L35.1847 15.6484V15.648Z"
        fill="#95875D"
      />
      <path
        d="M70.4683 0.824036H69.9341C68.4059 0.824036 67.0528 1.81701 66.5868 3.28085L60.7833 21.5094L59.0745 15.8371L57.2684 21.5094L51.7931 3.33388C51.3439 1.84317 49.9782 0.824036 48.4297 0.824036H46.0334C44.5102 0.824036 43.1603 1.811 42.6904 3.26883L36.8928 21.2531L35.1848 15.6483L33.3779 21.2531L32.0804 16.9952C31.22 18.6828 29.6339 20.5807 27.301 22.6932L25.6917 24.162L26.7052 27.3636L29.6968 36.7618H39.0986L45.1668 17.5594L46.8801 23.2602L48.6816 17.5594L54.4522 36.7618H63.8539L73.8155 5.43578C74.5409 3.155 72.849 0.824036 70.4679 0.824036H70.4683Z"
        fill="#222222"
      />
      <path
        d="M9.48644 2.23342C11.6792 0.987687 14.2588 0.270439 17.2146 0.0671774C16.6452 0.0258181 16.0642 0.000366211 15.4657 0.000366211C11.7565 0.000366211 8.59199 0.744834 5.9719 2.23342C3.35111 3.72235 1.36083 5.75002 0 8.31748L9.08543 12.8861C9.32477 12.3891 9.61472 11.9465 9.95176 11.5541L3.51489 8.31748C4.87572 5.75002 6.866 3.72235 9.48679 2.23377L9.48644 2.23342Z"
        fill="#3D5060"
      />
      <path
        d="M18.5214 17.3013C19.6443 16.2408 20.3922 15.3334 20.7672 14.5808C21.1415 13.8282 21.3288 13.0911 21.3288 12.3728C21.3288 11.4834 21.0308 10.7902 20.4357 10.2935C19.84 9.79758 18.9807 9.54907 17.8578 9.54907C17.2488 9.54907 16.6622 9.64346 16.096 9.82551C16.4109 9.94393 16.6871 10.0991 16.9209 10.2935C17.5159 10.7899 17.8139 11.4834 17.8139 12.3728C17.8139 13.0915 17.6266 13.8282 17.2523 14.5808C16.8773 15.3334 16.1294 16.2408 15.0065 17.3013L2.09302 29.3146V36.7589H5.60756V29.3146L18.5214 17.3013Z"
        fill="#3D5060"
      />
      <path
        d="M29.6963 36.7589L26.7058 27.364H23.1909L26.1814 36.7589H29.6963Z"
        fill="#EFC059"
      />
      <path
        d="M23.1911 27.364H26.7059L26.7056 27.3633L25.692 24.162L25.6927 24.1641L22.9675 26.658L23.1907 27.3633"
        fill="#3D5060"
      />
      <path
        d="M11.2803 10.4222C10.7742 10.7312 10.3327 11.1101 9.95142 11.5541L12.6 12.8861C13.1106 11.8263 13.842 11.0048 14.7948 10.4226C15.2148 10.1659 15.6488 9.96903 16.0955 9.82551C15.6081 9.6424 15.0247 9.54907 14.3425 9.54907C13.253 9.54907 12.2324 9.84 11.2799 10.4222H11.2803Z"
        fill="#EFC059"
      />
      <path
        d="M27.3006 22.6925C29.7162 20.5026 31.3409 18.5424 32.1753 16.8142C33.0086 15.0859 33.4254 13.2286 33.4254 11.2434C33.4254 11.2183 33.4229 11.1946 33.4229 11.1695C33.4208 10.8687 33.4078 10.5714 33.3815 10.279C33.379 10.2511 33.3751 10.2235 33.3723 10.1956C33.3425 9.89089 33.3024 9.59006 33.249 9.29489L33.2507 9.3009C32.9924 7.8565 32.4431 6.53407 31.588 5.33889C30.3628 3.62796 28.6611 2.31047 26.4838 1.38571C24.3059 0.461669 21.8049 0 18.9807 0C18.3758 0 17.7906 0.0272194 17.2146 0.0668112C19.3325 0.220937 21.2525 0.657507 22.969 1.38571C25.1462 2.31047 26.8483 3.62796 28.0731 5.33889C28.9282 6.53407 29.4775 7.8565 29.7358 9.3009L29.7341 9.29489C29.7875 9.59006 29.8276 9.89089 29.8574 10.1956C29.8599 10.2235 29.8641 10.2507 29.8666 10.279C29.8929 10.5714 29.9059 10.8687 29.9081 11.1695C29.9081 11.1942 29.9105 11.2183 29.9105 11.2434C29.9105 13.2286 29.4933 15.0859 28.6604 16.8142C27.826 18.5424 26.2013 20.5026 23.7857 22.6925L18.6816 27.3643H22.1965L25.6927 24.1645L25.692 24.1623L27.3006 22.6928V22.6925Z"
        fill="#EFC059"
      />
      <path
        d="M22.1778 24.1641L22.1771 24.1619L23.3439 23.0969L23.7857 22.6925C26.2012 20.5025 27.826 18.5424 28.6604 16.8141C29.4936 15.0859 29.9105 13.2286 29.9105 11.2433C29.9105 11.2182 29.908 11.1946 29.908 11.1695C29.9059 10.8686 29.8929 10.5713 29.8665 10.279C29.8641 10.2511 29.8602 10.2235 29.8574 10.1956C29.8275 9.89086 29.7875 9.59003 29.734 9.29486L29.7358 9.30087C29.4775 7.85648 28.9282 6.53404 28.0731 5.33886C26.8479 3.62793 25.1462 2.31044 22.9689 1.38569C21.2524 0.657835 19.3324 0.221264 17.2146 0.0671387C14.2588 0.2704 11.6795 0.987649 9.48679 2.23338C6.866 3.72196 4.87572 5.74998 3.51489 8.31709L9.95177 11.5537C10.3331 11.1097 10.7745 10.7311 11.2806 10.4218C12.2327 9.8396 13.2537 9.54867 14.3432 9.54867C15.0254 9.54867 15.6088 9.64164 16.0962 9.82511C16.6624 9.64306 17.249 9.54867 17.8581 9.54867C18.981 9.54867 19.8403 9.79718 20.436 10.2931C21.031 10.7895 21.329 11.483 21.329 12.3724C21.329 13.0911 21.1417 13.8278 20.7674 14.5804C20.3924 15.333 19.6445 16.2404 18.5216 17.3009L5.60745 29.3145V36.7589H26.1816L23.191 27.3639H18.6815L22.1778 24.1641Z"
        fill="#222222"
      />
    </svg>
  );
};
