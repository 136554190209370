import React from "react";

export const IconsDesktop = () => {
  return (
    <svg
      width="263"
      height="305"
      viewBox="0 0 263 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="80.8999" cy="266.424" r="36.3" fill="#EFC059" />
      <ellipse
        cx="80.4475"
        cy="263.999"
        rx="33.492"
        ry="26.0291"
        fill="#222222"
      />
      <ellipse
        cx="80.4473"
        cy="258.92"
        rx="34.9171"
        ry="26.0291"
        fill="#EFC059"
      />
      <rect x="150.2" y="230.125" width="74.8" height="72.6" fill="#3D5060" />
      <mask
        id="path-5-outside-1_89_3813"
        maskUnits="userSpaceOnUse"
        x="148.678"
        y="227.082"
        width="54"
        height="78"
        fill="black"
      >
        <rect fill="white" x="148.678" y="227.082" width="54" height="78" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.421 229.082H150.678V302.725H182.421L182.421 277.331C192.238 277.331 200.197 269.372 200.197 259.555C200.197 249.737 192.238 241.779 182.421 241.779V229.082Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.421 229.082H150.678V302.725H182.421L182.421 277.331C192.238 277.331 200.197 269.372 200.197 259.555C200.197 249.737 192.238 241.779 182.421 241.779V229.082Z"
        fill="#3D5060"
      />
      <path
        d="M150.678 229.082V227.082H148.678V229.082H150.678ZM182.421 229.082H184.421V227.082H182.421V229.082ZM150.678 302.725H148.678V304.725H150.678V302.725ZM182.421 302.725V304.725H184.421V302.725H182.421ZM182.421 277.331V275.331H180.421L180.421 277.331H182.421ZM182.421 241.779H180.421V243.779L182.421 243.779L182.421 241.779ZM150.678 231.082H182.421V227.082H150.678V231.082ZM152.678 302.725V229.082H148.678V302.725H152.678ZM182.421 300.725H150.678V304.725H182.421V300.725ZM180.421 277.331L180.421 302.725H184.421L184.421 277.331H180.421ZM198.197 259.555C198.197 268.268 191.134 275.331 182.421 275.331V279.331C193.343 279.331 202.197 270.477 202.197 259.555H198.197ZM182.421 243.779C191.134 243.779 198.197 250.842 198.197 259.555H202.197C202.197 248.633 193.343 239.779 182.421 239.779L182.421 243.779ZM180.421 229.082V241.779H184.421V229.082H180.421Z"
        fill="#222222"
        mask="url(#path-5-outside-1_89_3813)"
      />
      <path
        d="M0.599854 137.364L36.8999 110.281L73.1999 137.364V185.081H0.599854V137.364Z"
        fill="#3D5060"
      />
      <rect
        x="224.245"
        y="37.6941"
        width="13.9669"
        height="34.2823"
        rx="6.98343"
        fill="#EFC059"
      />
      <rect
        x="219.436"
        y="30.3455"
        width="23.5851"
        height="37.552"
        rx="8"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle cx="231.229" cy="30.7105" r="26.0291" fill="#EFC059" />
      <circle cx="140.3" cy="155.381" r="29.7" fill="#EFC059" />
      <circle
        cx="124.9"
        cy="155.381"
        r="30.7"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <path
        d="M260.2 186.081H261.2V185.081C261.2 167.553 247.489 153.281 230.5 153.281C213.511 153.281 199.8 167.553 199.8 185.081V186.081H200.8H230.5H260.2Z"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <circle
        cx="229.4"
        cy="143.281"
        r="18.6"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
      <rect x="106.2" y="0.281494" width="72.6" height="72.6" fill="#3D5060" />
      <rect
        x="7.19983"
        y="0.281494"
        width="72.6"
        height="19.8"
        fill="#EFC059"
      />
      <rect
        x="7.19983"
        y="26.6814"
        width="46.2"
        height="19.8"
        rx="2"
        fill="#EFC059"
      />
      <rect x="64.3999" y="26.6814" width="15.4" height="19.8" fill="#EFC059" />
      <rect
        x="44.5999"
        y="53.0813"
        width="35.2"
        height="19.8"
        rx="5"
        fill="#EFC059"
      />
      <rect x="7.19983" y="53.0813" width="30.8" height="19.8" fill="#EFC059" />
    </svg>
  );
};
