import React from "react";

export const Chart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_3943)">
        <path
          d="M14.4136 12.9999L21.1586 19.7449C22.77 17.8428 23.7459 15.4844 23.9496 12.9999H14.4136Z"
          fill="#374957"
        />
        <path
          d="M11.2925 12.707L10.9995 12.414V0.0500488C9.11535 0.204451 7.29429 0.801847 5.68477 1.79355C4.07525 2.78526 2.72282 4.14322 1.73767 5.75676C0.752523 7.3703 0.162546 9.19378 0.0158149 11.0786C-0.130916 12.9634 0.169752 14.8562 0.893316 16.6028C1.61688 18.3493 2.74287 19.9002 4.17952 21.1291C5.61617 22.3579 7.32283 23.23 9.16043 23.6741C10.998 24.1182 12.9146 24.1219 14.7539 23.6849C16.5932 23.2478 18.3032 22.3824 19.7445 21.159L11.2925 12.707Z"
          fill="#374957"
        />
        <path
          d="M13.0003 11H23.9503C23.7142 8.17492 22.4848 5.52483 20.4801 3.5202C18.4755 1.51557 15.8254 0.286106 13.0003 0.0500488V11Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_3943">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
