import React from "react";

export const EllipseAndUserMobile = () => {
  return (
    <svg
      width="184"
      height="59"
      viewBox="0 0 184 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="38.5" cy="30.4722" r="23.5" fill="#EFC059" />
      <circle
        cx="25.5"
        cy="30.4722"
        r="24.5"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <path
        d="M92.684 37.8242V22.9202H94.304V37.8242H92.684ZM85.844 31.1642V29.6162H101.144V31.1642H85.844Z"
        fill="#F3F8F1"
      />
      <path
        d="M178 53.4722H154.5H131C131 40.4935 141.521 29.9722 154.5 29.9722C167.479 29.9722 178 40.4935 178 53.4722Z"
        fill="#3D5060"
      />
      <circle
        cx="154.5"
        cy="20.4722"
        r="14.5"
        fill="#3D5060"
        stroke="#222222"
        strokeWidth="2"
      />
    </svg>
  );
};
