import React from "react";

export const Smartphone = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_3621)">
        <path d="M19.9998 0H3.99985V16H19.9998V0Z" fill="#374957" />
        <path
          d="M3.99985 18V21C3.99985 21.7956 4.31592 22.5587 4.87853 23.1213C5.44114 23.6839 6.2042 24 6.99985 24H16.9998C17.7955 24 18.5586 23.6839 19.1212 23.1213C19.6838 22.5587 19.9998 21.7956 19.9998 21V18H3.99985ZM12.9998 22H10.9998V20H12.9998V22Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_3621">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
