import React from "react";

export const Pyramid = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2280)">
        <path
          d="M0.586965 18.2399C0.28816 18.8497 0.149537 19.5256 0.184157 20.2038C0.218776 20.882 0.425499 21.5402 0.784846 22.1165C1.14419 22.6927 1.64433 23.168 2.23814 23.4975C2.83194 23.827 3.49986 23.9999 4.17896 23.9998H19.82C20.4991 24 21.1672 23.8272 21.7611 23.4978C22.3551 23.1684 22.8554 22.6931 23.2148 22.1169C23.5743 21.5406 23.7811 20.8823 23.8158 20.204C23.8504 19.5257 23.7118 18.8498 23.413 18.2399L22.806 16.9999H1.19396L0.586965 18.2399Z"
          fill="#374957"
        />
        <path
          d="M19.377 10.0001H4.62301L2.17401 15.0001H21.826L19.377 10.0001Z"
          fill="#374957"
        />
        <path
          d="M15.593 2.27485C15.2638 1.60259 14.7526 1.03618 14.1176 0.640007C13.4825 0.243838 12.749 0.0338135 12.0005 0.0338135C11.252 0.0338135 10.5185 0.243838 9.88338 0.640007C9.24831 1.03618 8.73713 1.60259 8.40798 2.27485L5.59998 7.99985H18.4L15.593 2.27485Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
