import React from "react";

export const LightbulbAndPentagonDesktop = () => {
  return (
    <svg
      width="225"
      height="81"
      viewBox="0 0 225 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="33.1035"
        y="40.2202"
        width="15.1724"
        height="37.2414"
        rx="7"
        fill="#EFC059"
      />
      <rect
        x="27.9655"
        y="32.3232"
        width="25.4483"
        height="40.6207"
        rx="8"
        fill="#EFC059"
        stroke="#222222"
        strokeWidth="2"
      />
      <ellipse
        cx="40.6897"
        cy="32.6338"
        rx="28.2759"
        ry="28.2759"
        fill="#EFC059"
      />
      <path
        d="M111.435 50.4992V31.0412H113.55V50.4992H111.435ZM102.505 41.8042V39.7832H122.48V41.8042H102.505Z"
        fill="#F3F8F1"
      />
      <path
        d="M145 29.1857L185 0.220215L225 29.1857V80.2202H145V29.1857Z"
        fill="#3D5060"
      />
    </svg>
  );
};
