import React from "react";

export const CommentUser = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2113)">
        <path
          d="M12.0002 7.94582C12.8413 7.94582 13.5232 7.26393 13.5232 6.4228C13.5232 5.58167 12.8413 4.89978 12.0002 4.89978C11.1591 4.89978 10.4772 5.58167 10.4772 6.4228C10.4772 7.26393 11.1591 7.94582 12.0002 7.94582Z"
          fill="#374957"
        />
        <path
          d="M19.9999 0.000610352H3.99988C1.77617 0.0181885 -0.0134218 1.83286 -0.000109307 4.05661V16.2266C-0.0134218 18.4505 1.77603 20.2654 3.99988 20.2836H6.92286L10.6708 23.4836C11.4132 24.162 12.5477 24.172 13.3018 23.5066L17.1469 20.2826H19.9999C22.2237 20.2644 24.0132 18.4495 23.9998 16.2256V4.05661C24.0132 1.83286 22.2236 0.0181885 19.9999 0.000610352ZM11.9999 3.04261C16.6149 3.16463 16.6139 10.0196 11.9999 10.1426C7.38491 10.0196 7.38589 3.16758 11.9999 3.04261ZM16.2489 17.2086C15.7137 17.3452 15.1693 17.022 15.0327 16.4869C15.0321 16.4844 15.0315 16.482 15.0309 16.4796C14.519 14.8054 12.7468 13.863 11.0726 14.3749C10.0648 14.683 9.27598 15.4718 8.96787 16.4796C8.82814 17.0142 8.28148 17.3343 7.74687 17.1946C7.21227 17.0549 6.89216 16.5082 7.03189 15.9736C7.80514 13.2296 10.6565 11.6319 13.4005 12.4052C15.1299 12.8925 16.4815 14.2441 16.9689 15.9736C17.1081 16.513 16.7869 17.064 16.2489 17.2086Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2113">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
