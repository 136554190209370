import React from "react";

export const Play = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2201)">
        <path
          d="M14.823 11.708C14.8752 11.7365 14.9186 11.7789 14.9484 11.8304C14.9782 11.8818 14.9933 11.9405 14.992 12C14.995 12.0507 14.9857 12.1015 14.9648 12.1478C14.9439 12.1941 14.912 12.2347 14.872 12.266L9.5 14.954C9.44884 14.9823 9.3912 14.9968 9.33275 14.996C9.2743 14.9953 9.21705 14.9794 9.16661 14.9498C9.11618 14.9202 9.0743 14.8781 9.04509 14.8274C9.01588 14.7768 9.00034 14.7195 9 14.661V9.339C8.99887 9.27966 9.01391 9.22114 9.0435 9.1697C9.0731 9.11826 9.11614 9.07585 9.168 9.047C9.21574 9.01946 9.26989 9.00498 9.325 9.005C9.40563 9.00737 9.48424 9.03082 9.553 9.073L14.823 11.708ZM24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5ZM16.992 12C16.9924 11.5787 16.8785 11.1651 16.6627 10.8033C16.4468 10.4414 16.1369 10.1448 15.766 9.945L10.488 7.31C10.1325 7.10604 9.72961 6.99915 9.31976 7.00007C8.9099 7.00099 8.5075 7.10968 8.15292 7.31524C7.79833 7.52079 7.50405 7.81599 7.29958 8.1712C7.09512 8.52641 6.98766 8.92914 6.988 9.339V14.661C6.9856 15.0708 7.09211 15.4738 7.29663 15.8289C7.50115 16.184 7.79635 16.4784 8.152 16.682C8.51201 16.8922 8.92113 17.0036 9.338 17.005C9.72366 17.0067 10.103 16.9071 10.438 16.716L15.814 14.029C16.1735 13.8266 16.4722 13.5317 16.6794 13.1749C16.8865 12.8182 16.9944 12.4125 16.992 12Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2201">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
