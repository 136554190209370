import React from "react";

import PageLayout from "../Layout/PageLayout";

import "../../styles/App.css";

const Impressum = (props) => {
  return (
    <PageLayout id={props.id}>
      <div className="flex flex-col  5xs:mt-14 xs:mt-40 overflow-x-hidden">
        <div className="xl:mb-14 3xl:mb-14 5xs:w-full 4xs:w-full 3xs:w-96 xxs:w-full xs:w-full sm:w-full md:w-[1140px] xl:w-[1140px]  2xl:w-[1190px] 3xl:w-[1190px] mx-auto xxs:px-4 5xs:px-4 break-words">
          <h5 className="5xs:text-2xl 4xs:text-4xl xxs:text-4xl xs:text-5xl sm:text-5xl md:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl font-Montserrat font-black text-blue-600 text-left">
            Impressum
          </h5>
          <h2 className="text-yellow-500 uppercase 5xs:text-2xl 4xs:text-4xl xxs:text-4xl xs:text-5xl sm:text-5xl md:text-5xl xl:text-6xl 2xl:text-6xl 3xl:text-6xl  mb-10 -mt-3 font-Montserrat font-light">
            Legal Notice
          </h2>

          <div className="flex flex-col flex-start gap-2 w-full h-[457px] 5xs:ml-0.5 ml-3 md:mb-20">
            <div className="flex flex-col flex-start isolate ">
              {/* Gray card */}
              <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[402px] xxs:w-[400px] xxs:h-[382px] md:w-[595px] md:h-[462px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
              {/* Yellow card  */}
              <div className="absolute bg-[#EFC059] z-10 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[402px] xxs:w-[400px] xxs:h-[382px] md:w-[595px] md:h-[462px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
              {/* Beige card */}
              <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[402px] xxs:w-[400px] xxs:h-[382px] md:w-[595px] md:h-[462px] rounded-tl	rounded-tr-[50px]">
                <div className="5xs:pt-10 5xs:pl-10">
                  <h2 className="font-Montserrat font-black text-blue-700 uppercase 5xs:text-2xl md:text-4xl xxs:text-3xl">
                    Verantwortlich
                  </h2>
                  <h3 className="font-Montserrat font-black xs:font-light text-yellow-500 tracking-tight uppercase 5xs:text-xl xxs:text-xl md:text-4xl">
                    für den Inhalt dieser <br />
                  </h3>
                  <h3 className="font-Montserrat font-black xs:font-light text-yellow-600 tracking-tight uppercase 5xs:text-xl xxs:text-xl md:text-4xl -mt-3">
                    Seiten:
                  </h3>
                </div>

                {/* card content  */}
                <div className="p-10 font-Raleway font-thin text-md md:text-2xl">
                  <p>2W Media GmbH</p>
                  <p>Schleißheimer Straße 181</p>
                  <p>80797 München</p>
                  <p>+49 89 55 05 68 67</p>
                  <p className="mb-8">USt-Id: DE223 207 462</p>
                  <p>Geschäftsführer: Benedikt Meirer</p>
                </div>
              </div>
            </div>
          </div>

          {/* Text content */}
          <div className="5xs:px-1 4xs:px-3 sm:px-1">
            <h2 className="5xs:text-3xl xxs:text-5xl font-Montserrat font-black text-yellow-500 mb-5 uppercase tracking-tight">
              Rechtshinweis
            </h2>

            <p className="font-Raleway font-normal text-yellow-100 mb-14 text-xl">
              Alle Angaben und Informationen, die in unseren Internetseiten
              enthalten sind, wurden von uns so sorgfältig wie möglich
              recherchiert und geprüft. Alle Informationen dienen ausschließlich
              zur Information der Besucher unserer Webseiten. Für Internetseiten
              Dritter, auf die diese Seite durch Hyperlinks verweist, tragen die
              jeweiligen Anbieter die volle Verantwortung. Alle Texte, Bilder,
              Graphiken, Ton-, Video- und Animationsdateien sowie ihre
              Arrangements unterliegen dem Urheberrecht und anderen Gesetzen zum
              Schutz geistigen Eigentums. Sie dürfen weder für Handelszwecke
              oder zur Weitergabe kopiert, noch verändert und auf anderen
              Web-Sites verwendet werden. Einige unserer Internet-Seiten
              enthalten auch Bilder, die dem Urheberrecht derjenigen
              unterliegen, die diese zur Verfügung gestellt haben. Sämtliche
              Vervielfältigungen, Verbreitung oder sonstige Benutzung des
              Inhalts der einzelnen Seiten oder der gesamten Webseite bedarf der
              Zustimmung von Keksfabrik. Jede weitere kommerzielle Nutzung ist
              untersagt.
            </p>

            <h2 className="5xs:text-3xl xxs:text-5xl font-Montserrat font-black text-yellow-500 mb-5 uppercase tracking-tight">
              Videos & Bilder
            </h2>
            <p className="font-Raleway font-normal text-yellow-100 mb-36 text-xl">
              Bilder die Sie hier finden gehören den Verfassern. Alle Rechte
              liegen bei diesen und eine Weiterverwendung bedarf einer
              gesonderten Zustimmung!
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Impressum;
