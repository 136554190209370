import React from "react";

export const EllipseAndPuzzleMobile = () => {
  return (
    <svg
      width="181"
      height="63"
      viewBox="0 0 181 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30.9722" r="29" fill="#EFC059" />
      <ellipse
        cx="29.5001"
        cy="29.4722"
        rx="26.3776"
        ry="20.5"
        fill="#222222"
      />
      <ellipse cx="29.5" cy="25.4722" rx="27.5" ry="20.5" fill="#EFC059" />
      <path
        d="M89.684 39.8242V24.9202H91.304V39.8242H89.684ZM82.844 33.1642V31.6162H98.144V33.1642H82.844Z"
        fill="#F3F8F1"
      />
      <rect x="123" y="2.97217" width="58" height="58" fill="#3D5060" />
      <mask
        id="path-6-outside-1_60_2706"
        maskUnits="userSpaceOnUse"
        x="121"
        y="0.972168"
        width="43"
        height="62"
        fill="black"
      >
        <rect fill="white" x="121" y="0.972168" width="43" height="62" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148 2.97217H123V60.9722H148V40.9722C155.732 40.9722 162 34.7042 162 26.9722C162 19.2402 155.732 12.9722 148 12.9722V2.97217Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148 2.97217H123V60.9722H148V40.9722C155.732 40.9722 162 34.7042 162 26.9722C162 19.2402 155.732 12.9722 148 12.9722V2.97217Z"
        fill="#3D5060"
      />
      <path
        d="M123 2.97217V0.972168H121V2.97217H123ZM148 2.97217H150V0.972168H148V2.97217ZM123 60.9722H121V62.9722H123V60.9722ZM148 60.9722V62.9722H150V60.9722H148ZM148 40.9722V38.9722H146V40.9722H148ZM148 12.9722H146V14.9722H148V12.9722ZM123 4.97217H148V0.972168H123V4.97217ZM125 60.9722V2.97217H121V60.9722H125ZM148 58.9722H123V62.9722H148V58.9722ZM146 40.9722V60.9722H150V40.9722H146ZM160 26.9722C160 33.5996 154.627 38.9722 148 38.9722V42.9722C156.837 42.9722 164 35.8087 164 26.9722H160ZM148 14.9722C154.627 14.9722 160 20.3448 160 26.9722H164C164 18.1356 156.837 10.9722 148 10.9722V14.9722ZM146 2.97217V12.9722H150V2.97217H146Z"
        fill="#222222"
        mask="url(#path-6-outside-1_60_2706)"
      />
    </svg>
  );
};
