import React from "react";
import NavBar from "../../components/Navigation/NavBar";
import Footer from "./Footer";

const PageLayout = ({ children, id }) => {
  return (
    <>
      <div className=" flex flex-col h-screen">
        {/* There are only 2 options available atm: Impressum / Datenschutz */}
        <NavBar id={id} />
        <div className="w-full sm:max-w-[1440px] mx-auto">{children}</div>

        <Footer id={id} />
      </div>
    </>
  );
};

export default PageLayout;
