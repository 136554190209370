import React from "react";

export const BigHeart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_3761)">
        <path
          d="M17.5002 0.917114C16.3742 0.934629 15.2727 1.24897 14.307 1.82839C13.3413 2.4078 12.5456 3.23178 12.0002 4.21711C11.4548 3.23178 10.6591 2.4078 9.69344 1.82839C8.72774 1.24897 7.62625 0.934629 6.5002 0.917114C4.70514 0.995105 3.01391 1.78037 1.79599 3.10135C0.578062 4.42232 -0.0675481 6.17164 0.000202431 7.96711C0.000202431 14.7421 10.9562 22.5671 11.4222 22.8991L12.0002 23.3081L12.5782 22.8991C13.0442 22.5691 24.0002 14.7421 24.0002 7.96711C24.068 6.17164 23.4223 4.42232 22.2044 3.10135C20.9865 1.78037 19.2953 0.995105 17.5002 0.917114Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_3761">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
