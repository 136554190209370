import React from "react";

// local imports
import { YellowGraySquares } from "../../Svgs/DetailsIcons/YellowGraySquaresMobile";
import { EllipseAndUserMobile } from "../../Svgs/DetailsIcons/EllipseAndUserMobile";
import { LightbulbAndPentagonMobile } from "../../Svgs/DetailsIcons/LightbulbAndPentagonMobile";
import { EllipseAndPuzzleMobile } from "../../Svgs/DetailsIcons/EllipseAndPuzzleMobile";
import { YellowGraySquaresTablet } from "../../Svgs/DetailsIcons/YellowGraySquaresTablet";
import { YellowGraySquaresDesktop } from "../../Svgs/DetailsIcons/YellowGraySquaresDesktop";
import { EllipseAndUserDesktop } from "../../Svgs/DetailsIcons/EllipseAndUserDesktop";
import { LightbulbAndPentagonDesktop } from "../../Svgs/DetailsIcons/LightbulbAndPentagonDesktop";
import { EllipseAndPuzzleDesktop } from "../../Svgs/DetailsIcons/EllipseAndPuzzleDesktop";

export const DetailsWithIcons = () => {
  return (
    <div className="flex flex-col justify-start 5xs:py-8 5xs:px-4 sm:px-8 5xs:w-[300px] 5xs:h-[2010px] 5xs:mb-28 xxs:w-[768px] xxs:h-[1733px] md:h-[2150px] sm:w-[1190px] sm2:w-[1195px] md:w-[1190px] md2:w-[1440px] xxs:mb-[150px]">
      {/* Mobile version All smartphones */}
      <div className="block xxs:hidden sm:hidden md:hidden lg:hidden">
        {/* First Square */}
        <div className="5xs:w-[315px] 5xs:h-[521px] 4xs:w-[350px]">
          <div className="5xs:py-8">
            <YellowGraySquares />
          </div>
          {/* Headline */}
          <div className="flex flex-col 5xs:h-16 5xs:gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row 5xs:h-[29px] 5xs:gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase 5xs:text-4xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase 5xs:text-4xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase 5xs:text-4xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-[280px] 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-base 4xs:text-xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="5xs:w-[300px] 5xs:h-[465px] 4xs:w-[369px]">
          <div className="5xs:py-8">
            <EllipseAndUserMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col 5xs:h-14 5xs:gap-1.5">
            {/* Headline inner*/}
            <div className="flex flex-row 5xs:h-[29px] 5xs:gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase 5xs:text-4xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase 5xs:text-4xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight 5xs:text-2xl 4xs:text-3xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-base 4xs:text-xl text-white break-words">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. Im Idealfall wird unser Ansprechpartner Ihre
              Vertrauensperson für all Ihre Anforderungen in Sachen
              Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="5xs:w-[300px] 5xs:h-[465px] 4xs:w-[370px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col 5xs:h-14 5xs:gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row 5xs:h-[29px] 5xs:gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase 5xs:text-3xl 4xs:text-4xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase  5xs:text-2xl 4xs:text-4xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase 5xs:text-3xl 4xs:text-4xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-base 4xs:text-xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="5xs:w-[310px] 5xs:h-[405px] 4xs:w-[360px]">
          <div className="5xs:py-8">
            <EllipseAndPuzzleMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col 5xs:h-16 5xs:gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row 5xs:h-[29px] 5xs:gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase 5xs:text-xl 4xs:text-[27px]">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase self-center 5xs:text-2xl 4xs:text-4xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight 5xs:text-4xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-36 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-base 4xs:text-xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}

        <div className="flex flex-col justify-start 5xs:w-72 5xs:h-11 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 5xs:py-2.5 5xs:px-5 5xs:w-72 5xs:h-11">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold 5xs:text-xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>
      {/* end of Mobile version */}

      {/* ipad Mini Version 768px */}
      <div className="hidden xxs:block xxs2:hidden sm:hidden md:hidden lg:hidden">
        {/* First Square */}
        <div className="w-[764px] h-[369px]">
          <div className="py-10">
            <YellowGraySquaresTablet />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-8">
            <p className="font-Raleway font-normal text-xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[735px] h-[391px]">
          <div className="py-8">
            <EllipseAndUserMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. Im Idealfall wird unser Ansprechpartner Ihre
              Vertrauensperson für all Ihre Anforderungen in Sachen
              Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[764px] h-[391px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-4">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[744px] h-[391px]">
          <div className="py-8">
            <EllipseAndPuzzleMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-14">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-11 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-72 h-11">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>
      {/* end of iPad Mini version */}

      {/* ipad Air Version 820px */}
      <div className="hidden xxs:hidden xxs2:block sm:hidden md:hidden lg:hidden">
        {/* First Square */}
        <div className="w-[764px] h-[369px]">
          <div className="py-10">
            <YellowGraySquaresTablet />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-8">
            <p className="font-Raleway font-normal text-xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[735px] h-[391px]">
          <div className="py-8">
            <EllipseAndUserMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. Im Idealfall wird unser Ansprechpartner Ihre
              Vertrauensperson für all Ihre Anforderungen in Sachen
              Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[764px] h-[391px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-4">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[744px] h-[391px]">
          <div className="py-8">
            <EllipseAndPuzzleMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-14">
            <p className="font-Raleway font-normal 5xs:text-xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-11 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-72 h-11">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>
      {/* end of iPad Air version  */}

      {/* ipad Pro Version 1024px */}
      <div className="hidden sm:block sm2:hidden md:hidden lg:hidden">
        {/* First Square */}
        <div className="w-[980px] h-[449px]">
          <div className="py-10">
            <YellowGraySquaresTablet />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[980px] h-[391px]">
          <div className="py-8">
            <EllipseAndUserMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. Im Idealfall wird unser Ansprechpartner Ihre
              Vertrauensperson für all Ihre Anforderungen in Sachen
              Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[980px] h-[391px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-4">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal text-3xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[980px] h-[391px]">
          <div className="py-8">
            <EllipseAndPuzzleMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-14">
            <p className="font-Raleway font-normal text-3xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-16 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-96 h-44">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-3xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>
      {/* end of iPad Pro Version */}

      {/* Nest Hub Max 1280px */}
      <div className="hidden sm:hidden sm2:block md:hidden lg:hidden">
        {/* First Square */}
        <div className="w-[1190px] h-[449px]">
          <div className="py-10">
            <YellowGraySquaresTablet />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[1190px] h-[391px]">
          <div className="py-8">
            <EllipseAndUserMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. Im Idealfall wird unser Ansprechpartner Ihre
              Vertrauensperson für all Ihre Anforderungen in Sachen
              Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[1190px] h-[391px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-4">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-5xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal text-3xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[1190px] h-[391px]">
          <div className="py-8">
            <EllipseAndPuzzleMobile />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-5xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-5xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-5xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-14">
            <p className="font-Raleway font-normal text-3xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-16 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-96 h-44">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-3xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>

      {/* end of Nest Hub Max */}

      {/* small desktops 1300px */}
      <div className="hidden sm:hidden sm2:hidden md:block lg:hidden">
        {/* First Square */}
        <div className="w-[860px] h-[515px]">
          <div className="py-10">
            <YellowGraySquaresDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-6 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-6xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-16">
            <p className="font-Raleway font-normal text-2xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. <br /> <br />
              Aus komplexen Sachverhalten filtern wir das Essenzielle heraus und
              machen das Komplizierte einfach. Deshalb lieben vor allem
              B2B-Kunden unsere Services für interne Kommunikation, E-Learning
              und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[900px] h-[505px]">
          <div className="py-8">
            <EllipseAndUserDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-6">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-6xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal text-2xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. <br /> <br /> Im Idealfall wird unser
              Ansprechpartner Ihre Vertrauensperson für all Ihre Anforderungen
              in Sachen Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[900px] h-[505px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-6">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-6xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-24">
            <p className="font-Raleway font-normal text-2xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. <br /> <br /> Wir
              decken inhouse Bereiche wie Konzept und Text, Kamera, Regie,
              Grafik, Schnitt, Performance Marketing, Analytics und Development
              ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[720px] h-[445px]">
          <div className="py-8">
            <EllipseAndPuzzleDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 w-[820px] gap-6">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-6xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-20">
            <p className="font-Raleway font-normal text-2xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-16 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-96 h-44">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-3xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>
      {/* end of small desktops version */}

      {/* Large Desktops 1440px +  */}
      <div className="hidden sm:hidden md:hidden lg:block">
        {/* First Square */}
        <div className="w-[900px] h-[449px]">
          <div className="py-10">
            <YellowGraySquaresDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Komplex
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-6xl">
              Einfach
            </h2>
          </div>

          {/* Content */}
          <div className="h-[140px] pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Wenn's darum geht, etwas Neues verständlich rüberzubringen, sind
              wir dabei. Aus komplexen Sachverhalten filtern wir das Essenzielle
              heraus und machen das Komplizierte einfach. Deshalb lieben vor
              allem B2B-Kunden unsere Services für interne Kommunikation,
              E-Learning und Erklärfilme.
            </p>
          </div>
        </div>

        {/* 2nd square */}
        <div className="w-[900px] h-[391px]">
          <div className="py-8">
            <EllipseAndUserDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-20 gap-1.5 ">
            {/* Headline inner*/}
            <div className="flex flex-row h-9 gap-0.5">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                2W<span className="font-light">elten</span>
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-6xl">
              1 Ansprechpartner
            </h2>
          </div>

          {/* Content */}
          <div className="5xs:h-56 5xs:pt-8">
            <p className="font-Raleway font-normal text-3xl text-white">
              Bei uns haben Sie einen festen Ansprechpartner &#8211; Ihr Lotse
              durch die Services der 2Welten, berät Sie und gibt Ihre Ideen an
              unser Team weiter. <br /> <br /> Im Idealfall wird unser
              Ansprechpartner Ihre Vertrauensperson für all Ihre Anforderungen
              in Sachen Kommunikation und Marketing.
            </p>
          </div>
        </div>

        {/* 3rd square */}
        <div className="w-[900px] h-[391px]">
          <div className="5xs:py-8">
            <LightbulbAndPentagonDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-14 gap-4">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-3">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Spezialisten
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase text-6xl">
              Inhouse
            </h2>
          </div>

          {/* Content */}
          <div className="h-56 pt-16">
            <p className="font-Raleway font-normal text-3xl text-white">
              In jedem Bereich beschäftigen wir versierte Spezialist:innen, die
              den geschulten Blick für das Wesentliche haben. Wir decken inhouse
              Bereiche wie Konzept und Text, Kamera, Regie, Grafik, Schnitt,
              Performance Marketing, Analytics und Development ab.
            </p>
          </div>
        </div>

        {/* 4th square */}
        <div className="w-[700px] h-[391px]">
          <div className="py-8">
            <EllipseAndPuzzleDesktop />
          </div>
          {/* Headline */}
          <div className="flex flex-col h-16 gap-3">
            {/* Headline inner*/}
            <div className="flex flex-row h-[29px] gap-1">
              <h2 className="font-Montserrat font-black pr-3 text-yellow-500 uppercase text-6xl">
                Rundum Glücklich
              </h2>
              <h2 className="font-Montserrat font-light text-right text-[#F3F8F1] uppercase text-6xl">
                +
              </h2>
            </div>

            <h2 className="font-Montserrat font-black pr-3 text-darkblue-400 uppercase tracking-tight text-6xl">
              Modular
            </h2>
          </div>

          {/* Content */}
          <div className="h-36 pt-14">
            <p className="font-Raleway font-normal text-3xl text-white">
              Gerne begleiten wir Projekte von der ersten Konzeption bis zum
              Upload ins Web. Unsere Services lassen sich aber auch ganz
              flexibel einzeln buchen.
            </p>
          </div>
        </div>

        {/* Contact btn wrapper  */}
        <div className="flex flex-col justify-start w-32 h-16 cursor-pointer">
          <div className="flex flex-row items-center rounded-tl-md rounded-tr-2xl	bg-yellow-500 py-2.5 px-5 w-96 h-44">
            <a
              href="mailto:info@2wmedia.de"
              className="font-Roboto font-bold text-3xl "
            >
              Kontakt aufnehmen
            </a>
          </div>
        </div>
      </div>

      {/* end of large desktops  */}
    </div>
  );
};
