/* eslint-disable react/jsx-no-comment-textnodes */

//external imports
import React from "react";

//Local files - folders - locations
import PageLayout from "../Layout/PageLayout";
import { HeroImage } from "./HomeComponents/HeroImage";

// styles
import "../../styles/App.css";
import { SliderComponent } from "./HomeComponents/SliderComponent";
import { WeltCards } from "./HomeComponents/WeltCards";
import { FirstDivider } from "./HomeComponents/FirstDivider";
import { DetailsWithIcons } from "./HomeComponents/DetailsWithIcons";

const Home = (props) => {
  let id = props.id;

  return (
    <PageLayout id={id}>
      <div className="flex flex-col xxs:-mt-0.5 overflow-x-hidden">
        <div className="xl:mb-14 3xl:mb-14 w-full mx-auto  break-words">
          {/* HeroComponent */}
          <HeroImage />

          {/* Carousel */}
          <SliderComponent />

          {/* Welt 1 and 2 cards  */}
          <WeltCards />

          {/* Icons with Headline Divider =>  */}
          <FirstDivider />

          {/* Details + icons */}
          <DetailsWithIcons />
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
