import React from "react";

export const ArrowsTabletVersion2 = () => {
  return (
    <svg
      width="1035"
      height="484"
      viewBox="0 0 834 484"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M839.98 483.738C773.857 483.731 711.694 458.997 664.944 414.092C618.192 369.187 592.449 309.485 592.456 245.986L592.483 0.893188L664.068 112.504L623.185 112.5L623.171 244.08C623.158 359.952 721.311 454.23 841.973 454.243L1036.18 454.264L1036.18 483.76L839.979 483.738L839.98 483.738Z"
        fill="#EFC059"
      />
      <path
        d="M135.944 483.662L135.948 454.166L548.98 454.211L549.017 112.493L508.135 112.489L579.744 0.891872L579.691 483.71L135.944 483.662Z"
        fill="#3D5060"
      />
      <rect
        x="-261.797"
        y="454.369"
        width="431.008"
        height="29.0169"
        fill="#3D5060"
      />
    </svg>
  );
};
