import React from "react";

import HeroImageMobile from "../../Assets/img/heroImage-mobile.jpeg";
import HeroImageTabletDesktop from "../../Assets/img/heroImage-tablet-desktop.png";

export const HeroImage = () => {
  return (
    <>
      {/* Hero image & title  */}
      <div className="grid 5xs:grid-cols-1 xxs:grid-cols-2 items-center 5xs:mb-1 xxs:mb-16 ">
        {/* image container */}
        {/* We had to build several img containers due to a bug in tailwind.config
            //TODO Improve hero image component by using tailwind.config file
            for each BP.
             */}
        <div className="w-full">
          <div className="block xxs:hidden sm:hidden md:hidden lg:hidden">
            <img alt="hero" src={HeroImageMobile} className="h-4/5 w-full" />
          </div>
          <div className="hidden xxs:block sm:hidden md:hidden lg:hidden">
            <img
              alt="hero"
              src={HeroImageTabletDesktop}
              className="h-4/5 w-full"
            />
          </div>
          <div className="hidden sm:hidden md:hidden lg:hidden">
            <img
              alt="hero"
              src={HeroImageTabletDesktop}
              className="h-4/5 w-full"
            />
          </div>
          <div className="hidden sm:block md:hidden lg:hidden">
            <img
              alt="hero"
              src={HeroImageTabletDesktop}
              className="h-4/5 w-full"
            />
          </div>
          <div className="hidden sm:hidden md:block lg:hidden">
            <img
              alt="hero"
              src={HeroImageTabletDesktop}
              className="h-full w-full"
            />
          </div>
          <div className="hidden sm:hidden md:hidden lg:block">
            <img
              alt="hero"
              src={HeroImageTabletDesktop}
              className="h-96 w-full"
            />
          </div>
        </div>
        {/* text container  */}
        <div className="5xs:py-10 5xs:px-4 xxs:pl-10 md:p-10 ">
          <h1 className="font-Montserrat font-black uppercase tracking-tighter text-blue-600 text-5xl 5xs:text-4xl xxs:text-5xl sm:text-7xl 4xs:mb-1 5xs:tracking-normal 5xs:leading-7 xxs:leading-9 sm:leading-[50px] 5xs:mb-1">
            Entweder oder?
          </h1>
          <h2 className="font-Montserrat font-light uppercase text-yellow-500 tracking-tight 5xs:-mt-2 5xs:text-3xl 4xs:tracking-wider 4xs:text-4xl 4xs:text-4xl  xxs:text-5xl sm:text-7xl">
            Sowohl
          </h2>

          <span className="">
            {" "}
            <h2 className="font-Montserrat font-light uppercase text-yellow-500 tracking-tight xxs:-mt-4 md:-mt-6 5xs:mb-3 5xs:text-3xl 5xs:-mt-3 4xs:-mt-3 4xs:text-4xl xxs:-mt-2 xxs:text-5xl sm:text-7xl">
              als auch!
            </h2>
          </span>
          <div className="5xs:text-sm 4xs:text-base md:text-2xl xxs:text-xl font-thin break-words italic md:not-italic 4xs:text-xl">
            <p className="text-white font-Raleway 4xs:mb-5 md:mb-8 xxs:mb-10">
              Wir sind die{" "}
              <span className="font-black">Kommunikationsagentur</span>{" "}
              <span className="font-black">2W</span>media.
            </p>
            <p className="text-yellow-500 font-Raleway 4xs:mb-5 md:mb-8 xxs:mb-10">
              <span className="font-bold">2W</span> steht für{" "}
              <span className="font-bold">2W</span>elten.
            </p>
            <p className="text-white font-Raleway">
              Das passt gut, denn: <br />
              Wir schlagen eine Brücke zwischen ...
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
