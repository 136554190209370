import React, { useState } from "react";
import anime from "animejs/lib/anime.es.js";
import { HashLink } from "react-router-hash-link";
import { useNavigate, Link } from "react-router-dom";

// local imports
import { NavBarLogo } from "../Svgs/NavBar/NavBarLogo";
import { NavBarLogoPositive } from "../Svgs/NavBar/NavBarLogoPositive";

import {
  CloseIconPath,
  HamburguerMenuIconPath,
} from "../Svgs/NavBar/NavBarIcons";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const navigate = useNavigate();

  // Toggle to show menu and then go back to default state =>
  let boxGetsLarger = anime({
    targets: "#menuWrapper",
    height: "300px",
    easing: "spring(1, 80, 10, 0)",
    loop: false,
  });

  const displayMenu = document.querySelector("#menuWrapper");
  const buttonClickHandler = () => {
    boxGetsLarger.play();
    boxGetsLarger.finished.then(() => {
      boxGetsLarger.reverse();
    });
  };
  displayMenu?.addEventListener("click", buttonClickHandler);

  const toHome = () => {
    navigate("/");
  };

  return (
    <div className="w-full">
      <nav
        className={`flex items-center justify-between flex-wrap 5xs:px-4 4xs:py-4 xxs:py-4 xxs2:py-4 max-w-[1480px] mx-auto sm2:py-5 md2:py-6 ${
          isOpen ? "bg-[#F3F8F1]" : "none"
        }`}
      >
        <div
          className="flex items-center flex-shrink-0 text-white py-1"
          onClick={() => {
            toHome();
          }}
        >
          {isOpen ? (
            <NavBarLogoPositive id="iconPositive" />
          ) : (
            <NavBarLogo id="iconNegative" />
          )}
        </div>

        <svg
          className="cursor-pointer"
          id="menuIcon"
          width="44"
          height="40"
          viewBox="0 0 44 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(event) => {
            const timeline = anime.timeline({
              // this is only for the top right icon
              duration: 190,
              easing: "easeOutQuad",
            });

            // TODO : animation: morph from hamburguer icon to close icon - bug: morph not working properly =>
            timeline.add({
              targets: ".navMenuTopIcon",
              d: [
                { value: toggleView ? HamburguerMenuIconPath : CloseIconPath },
              ],
            });

            // display menu
            setIsOpen(!isOpen);
            setToggleView(!toggleView);
            buttonClickHandler();
          }}
        >
          <path
            className="navMenuTopIcon"
            d="M42 3C42.8284 3 43.5 2.32843 43.5 1.5C43.5 0.671573 42.8284 0 42 0V3ZM2 0H0.5V3H2V0ZM31 15.5C31.8284 15.5 32.5 14.8284 32.5 14C32.5 13.1716 31.8284 12.5 31 12.5V15.5ZM2 12.5H0.5V15.5H2V12.5ZM42 28C42.8284 28 43.5 27.3284 43.5 26.5C43.5 25.6716 42.8284 25 42 25V28ZM2 25H0.5V28H2V25ZM2 3H42V0H2V3ZM2 15.5H31V12.5H2V15.5ZM2 28H42V25H2V28Z"
            fill={isOpen ? "#222222" : "#F3F8F1"}
          />
        </svg>

        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div
            className="font-Montserrat lg:flex-grow bg-[#F3F8F1] leading-[1rem] 5xs:pt-10 5xs:px-10 5xs:pb-[9px] xxs:pb-[38px]"
            id="menuWrapper"
          >
            <HashLink
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
              to={"/page#services"}
              className="block mt-1 lg:inline-block mr-4 uppercase font-black  5xs:text-4xl 4xs:text-4xl"
            >
              Leistungen
            </HashLink>
            <HashLink
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
              to={"/page#about-us"}
              className="block mt-1 lg:inline-block mr-4 uppercase font-black  5xs:text-4xl 4xs:text-4xl"
            >
              Über uns
            </HashLink>

            <HashLink
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
              to={"/page#contact"}
              className="block mt-1 lg:inline-block mr-4 uppercase font-black  5xs:text-4xl 4xs:text-4xl"
            >
              kontakt
            </HashLink>

            <Link
              to={"/impressum"}
              className="font-light block mt-2 lg:inline-block mr-4 uppercase 5xs:text-xl 4xs:text-xl"
            >
              impressum
            </Link>
            <Link
              to={"/datenschutz"}
              className="font-light block mt-2 lg:inline-block mr-4 uppercase 5xs:text-xl 4xs:text-xl"
            >
              datenschutz
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
