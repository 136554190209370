import React from "react";

export const FlowerTulip = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5351 14.7247C23.328 14.4732 23.0623 14.2763 22.7614 14.1515C22.4605 14.0267 22.1335 13.9776 21.8091 14.0087C19.9429 14.118 18.1404 14.7273 16.5905 15.7727C15.0407 16.8182 13.8006 18.2612 13.0001 19.9507V13.9157C14.3974 13.6777 15.6656 12.9534 16.5806 11.8708C17.4955 10.7883 17.9983 9.41711 18.0001 7.99969C18.0001 5.20669 16.0571 2.84769 14.1561 0.908691C14.0462 0.796087 13.9271 0.692737 13.8001 0.599691C12.6517 2.88765 12.0364 5.40596 12.0001 7.96569C12.0046 8.09701 11.9832 8.22793 11.937 8.35096C11.8909 8.474 11.821 8.58674 11.7313 8.68276C11.6416 8.77877 11.5338 8.85618 11.4142 8.91056C11.2946 8.96494 11.1655 8.99523 11.0341 8.99969C10.9028 9.00416 10.7719 8.98271 10.6489 8.93658C10.5258 8.89045 10.4131 8.82054 10.3171 8.73084C10.2211 8.64114 10.1436 8.5334 10.0893 8.41379C10.0349 8.29417 10.0046 8.16501 10.0001 8.03369C10.0107 5.26268 10.6415 2.52918 11.8461 0.0336914C11.0934 0.0635741 10.3794 0.375575 9.84614 0.907691C7.94314 2.84769 6.00014 5.20669 6.00014 7.99969C6.00195 9.41711 6.50476 10.7883 7.41971 11.8708C8.33466 12.9534 9.60284 13.6777 11.0001 13.9157V19.9517C10.1999 18.262 8.95992 16.8187 7.41005 15.773C5.86018 14.7274 4.05755 14.118 2.19114 14.0087C1.86683 13.9778 1.53989 14.0269 1.23897 14.1517C0.938059 14.2766 0.672352 14.4733 0.465138 14.7247C0.259251 14.9691 0.115898 15.26 0.0474331 15.5722C-0.0210313 15.8844 -0.0125527 16.2085 0.0721381 16.5167C2.11114 23.8547 11.5911 23.9997 11.9941 23.9997H12.0061C12.4061 23.9997 21.8901 23.8547 23.9291 16.5167C24.0133 16.2084 24.0215 15.8843 23.9529 15.5722C23.8842 15.26 23.7409 14.9692 23.5351 14.7247Z"
        fill="#374957"
      />
    </svg>
  );
};
