import React from "react";

export const LogoFooter = () => {
  return (
    <div className="flex-none order-1 grow-0 -mt-8">
      <div className="w-40 shadow-lg m-auto">
        <svg
          width="154"
          height="118"
          viewBox="0 0 154 118"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M47.8801 96.6102C47.2938 95.7327 46.557 95.0634 45.6702 94.6015C44.7835 94.1411 43.6928 93.9102 42.4009 93.9102C40.4463 93.9102 38.7027 94.4389 37.1693 95.4949C35.6359 96.5516 34.3128 98.1212 33.2006 100.204C32.9591 98.2998 32.2153 96.7743 30.9678 95.6286C29.7196 94.4837 28.1189 93.9102 26.1651 93.9102C24.3 93.9102 22.5869 94.4237 21.0237 95.4501C19.4605 96.4765 18.1825 97.942 17.1903 99.8466V94.2679H15.0706V117.522H17.3256V103.149C17.957 101.009 19.0171 99.2779 20.5054 97.9544C21.9937 96.6316 23.6998 95.9705 25.6239 95.9705C27.518 95.9705 28.9161 96.6619 29.8181 98.0454C30.7201 99.4289 31.1711 101.608 31.1711 104.582V117.522H33.4261V103.199C34.0269 101.087 35.0725 99.3544 36.5608 98.0006C38.0491 96.6468 39.7553 95.9705 41.6793 95.9705C43.5734 95.9705 44.9785 96.6702 45.8964 98.0675C46.813 99.4654 47.2716 101.637 47.2716 104.582V117.522H49.5266V104.175C49.5266 102.569 49.3982 101.125 49.1436 99.8459C48.8875 98.5665 48.4671 97.487 47.8808 96.6095L47.8801 96.6102Z"
            fill="#EFC059"
          />
          <path
            d="M72.0756 95.5618C70.3618 94.4602 68.392 93.9102 66.1676 93.9102C64.0028 93.9102 62.0476 94.4533 60.3046 95.5397C58.5603 96.6261 57.1928 98.0619 56.2006 99.8473C55.2084 101.633 54.7123 103.626 54.7123 105.829C54.7123 107.465 55.0051 109.021 55.5921 110.494C56.1784 111.968 56.9902 113.262 58.0275 114.378C59.0648 115.494 60.2894 116.372 61.7034 117.011C63.1161 117.651 64.6495 117.972 66.3036 117.972C67.7468 117.972 69.1219 117.726 70.4298 117.232C71.7377 116.739 72.8805 116.06 73.8574 115.194C74.8343 114.327 75.5489 113.341 75.9999 112.236L74.0156 111.699C73.4445 113.013 72.4371 114.089 70.9939 114.926C69.5507 115.762 68.0173 116.179 66.3938 116.179C64.7702 116.179 63.2743 115.771 61.9067 114.954C60.5378 114.136 59.4186 113.014 58.5465 111.587C57.6743 110.16 57.1782 108.569 57.0582 106.815H77.6235C77.6527 106.696 77.6686 106.547 77.6686 106.37V105.833C77.6686 103.691 77.1649 101.718 76.1581 99.9176C75.15 98.1171 73.7901 96.6654 72.0763 95.5638L72.0756 95.5618ZM56.9673 105.066C57.0873 103.253 57.5682 101.648 58.4105 100.251C59.2514 98.8533 60.3498 97.7531 61.7027 96.9514C63.0557 96.1491 64.5586 95.7472 66.2127 95.7472C67.8668 95.7472 69.3766 96.1491 70.7448 96.9514C72.1124 97.7531 73.2177 98.8533 74.06 100.251C74.9009 101.648 75.3824 103.253 75.5032 105.066H56.9673Z"
            fill="#EFC059"
          />
          <path
            d="M103.194 114.566V84.8145H100.939V99.3764C100.037 97.8833 98.796 96.5984 97.2182 95.5231C95.6397 94.4477 93.8281 93.9101 91.7834 93.9101C89.7088 93.9101 87.8597 94.4684 86.2362 95.5844C84.6126 96.6997 83.3345 98.1659 82.4027 99.9816C81.4702 101.797 81.0046 103.79 81.0046 105.964C81.0046 107.511 81.2821 108.999 81.8393 110.428C82.3951 111.856 83.177 113.136 84.1845 114.266C85.1912 115.398 86.3867 116.298 87.7695 116.967C89.1523 117.636 90.6559 117.972 92.2795 117.972C93.9933 117.972 95.6619 117.508 97.2855 116.582C98.9091 115.656 100.201 114.417 101.164 112.863V115.462C101.164 115.94 101.344 116.388 101.705 116.806C102.066 117.224 102.517 117.478 103.058 117.568C103.298 117.597 103.658 117.582 104.141 117.523V115.686C103.84 115.656 103.606 115.551 103.441 115.372C103.276 115.193 103.194 114.925 103.194 114.566V114.566ZM100.939 109.71C100.848 110.543 100.525 111.338 99.9693 112.096C99.4121 112.855 98.7211 113.524 97.8947 114.104C97.0676 114.683 96.1962 115.137 95.2789 115.464C94.361 115.792 93.4972 115.955 92.6854 115.955C91.3622 115.955 90.1376 115.695 89.0101 115.174C87.8826 114.654 86.8904 113.925 86.0335 112.988C85.1766 112.052 84.5071 110.981 84.0263 109.777C83.5448 108.573 83.3047 107.317 83.3047 106.008C83.3047 104.73 83.5149 103.487 83.9361 102.283C84.3565 101.079 84.9657 100.001 85.7623 99.049C86.5588 98.0977 87.5059 97.3401 88.6035 96.7749C89.7005 96.2096 90.9258 95.927 92.2788 95.927C93.4208 95.927 94.5789 96.202 95.7514 96.7521C96.924 97.3029 97.9766 98.0453 98.9084 98.9821C99.8402 99.9182 100.516 100.952 100.938 102.083V109.71H100.939Z"
            fill="#EFC059"
          />
          <path
            d="M112.619 94.2241H110.364V117.523H112.619V94.2241Z"
            fill="#EFC059"
          />
          <path
            d="M137.31 115.374C137.145 115.195 137.063 114.928 137.063 114.571V103.058C137.063 100.232 136.251 98.0013 134.627 96.3648C133.004 94.7291 130.794 93.9102 127.998 93.9102C126.583 93.9102 125.163 94.1721 123.735 94.6946C122.307 95.2178 120.827 95.9871 119.293 97.0025L120.24 98.6155C121.562 97.7187 122.856 97.0321 124.119 96.5544C125.381 96.0774 126.599 95.8375 127.772 95.8375C129.966 95.8375 131.688 96.4765 132.936 97.7531C134.183 99.0311 134.807 100.739 134.807 102.878V105.068C133.784 104.709 132.627 104.426 131.334 104.217C130.042 104.008 128.793 103.903 127.591 103.903C125.666 103.903 123.967 104.193 122.494 104.775C121.021 105.356 119.863 106.159 119.021 107.186C118.179 108.214 117.759 109.427 117.759 110.826C117.759 112.166 118.097 113.379 118.774 114.466C119.451 115.553 120.381 116.409 121.57 117.035C122.757 117.659 124.088 117.972 125.561 117.972C127.425 117.972 129.206 117.598 130.905 116.852C132.603 116.106 133.994 115.059 135.077 113.715V115.552C135.107 116.03 135.28 116.463 135.596 116.852C135.911 117.241 136.355 117.479 136.927 117.569H137.377C137.557 117.569 137.783 117.554 138.054 117.524V115.687C137.722 115.658 137.475 115.553 137.31 115.375L137.31 115.374ZM134.808 110.646C134.808 110.945 134.733 111.271 134.582 111.628C134.431 111.986 134.206 112.358 133.906 112.744C133.184 113.756 132.109 114.581 130.681 115.22C129.253 115.86 127.697 116.179 126.013 116.179C124.811 116.179 123.751 115.927 122.834 115.421C121.917 114.916 121.202 114.247 120.691 113.413C120.18 112.58 119.925 111.673 119.925 110.692C119.925 109.115 120.654 107.865 122.112 106.944C123.57 106.022 125.472 105.56 127.817 105.56C129.049 105.56 130.253 105.664 131.425 105.872C132.598 106.081 133.725 106.393 134.808 106.809V110.646V110.646Z"
            fill="#EFC059"
          />
          <path
            d="M111.398 89.6165C112.854 89.6165 114.033 88.4443 114.033 86.9984C114.033 85.5525 112.854 84.3804 111.398 84.3804C109.943 84.3804 108.763 85.5525 108.763 86.9984C108.763 88.4443 109.943 89.6165 111.398 89.6165Z"
            fill="#EFC059"
          />
          <path
            d="M84.7791 7.34548C85.7067 4.5034 88.3718 2.57812 91.3789 2.57812H84.4398C81.4327 2.57812 78.7677 4.50271 77.84 7.34548L69.9615 31.4855L73.3335 42.4147L84.7791 7.34548Z"
            fill="#3D5060"
          />
          <path
            d="M131.955 7.36989C132.875 4.51541 135.546 2.5791 138.563 2.5791H131.624C128.607 2.5791 125.936 4.51541 125.016 7.36989L117.124 31.8546L120.497 42.9155L131.955 7.36989H131.955Z"
            fill="#3D5060"
          />
          <path
            d="M93.051 46.3291L101.061 72.6571H108L96.6076 35.2124L93.051 46.3291Z"
            fill="#3D5060"
          />
          <path
            d="M102.75 7.47329L113.559 42.9155L117.125 31.8546L109.689 7.47329C108.803 4.56642 106.106 2.5791 103.049 2.5791H96.1101C99.1672 2.5791 101.864 4.56711 102.75 7.47329Z"
            fill="#EFC059"
          />
          <path
            d="M146.556 2.5791H139.617C144.317 2.5791 147.657 7.12449 146.226 11.572L126.561 72.657H133.5L153.165 11.572C154.597 7.12449 151.257 2.5791 146.556 2.5791Z"
            fill="#EFC059"
          />
          <path
            d="M77.6888 72.6571H84.6278L93.051 46.3291L89.6692 35.2124L77.6888 72.6571Z"
            fill="#EFC059"
          />
          <path
            d="M69.9615 31.4857L66.1398 19.0972C66.3639 20.3207 66.4895 21.5863 66.4895 22.8933C66.4895 26.7728 65.6639 30.3876 64.0264 33.7528C63.9688 33.8707 63.8946 33.9934 63.8328 34.1127L66.3945 42.4155L69.9615 31.4864V31.4857Z"
            fill="#95875D"
          />
          <path
            d="M139.618 2.5791H138.563C135.546 2.5791 132.875 4.51541 131.955 7.36989L120.498 42.9155L117.124 31.8546L113.559 42.9155L102.749 7.47329C101.863 4.56642 99.1665 2.5791 96.1095 2.5791H91.3789C88.3718 2.5791 85.7067 4.50369 84.7791 7.34645L73.3335 42.4157L69.9615 31.4865L66.3945 42.4157L63.8328 34.1128C62.1343 37.4037 59.003 41.1046 54.3973 45.224L51.2202 48.0881L53.2213 54.3313L59.1272 72.6576H77.6881L89.6679 35.213L93.0503 46.3297L96.6069 35.213L107.999 72.6576H126.56L146.226 11.572C147.658 7.12449 144.318 2.5791 139.617 2.5791H139.618Z"
            fill="#F3F8F1"
          />
          <path
            d="M19.2281 5.3271C23.5569 2.89793 28.6497 1.4993 34.4849 1.10294C33.3608 1.02229 32.2139 0.972656 31.0323 0.972656C23.7096 0.972656 17.4623 2.42437 12.2897 5.3271C7.11574 8.23053 3.18654 12.1845 0.5 17.191L18.4364 26.0998C18.9089 25.1306 19.4813 24.2676 20.1467 23.5025L7.43907 17.191C10.1256 12.1845 14.0548 8.23053 19.2288 5.32779L19.2281 5.3271Z"
            fill="#3D5060"
          />
          <path
            d="M37.0646 34.7096C39.2814 32.6416 40.7579 30.8721 41.4982 29.4046C42.2371 27.937 42.6069 26.4998 42.6069 25.0991C42.6069 23.3647 42.0186 22.013 40.8439 21.0445C39.6678 20.0774 37.9714 19.5928 35.7546 19.5928C34.5522 19.5928 33.3942 19.7768 32.2764 20.1318C32.8981 20.3627 33.4434 20.6654 33.9048 21.0445C35.0795 22.0123 35.6679 23.3647 35.6679 25.0991C35.6679 26.5005 35.298 27.937 34.5591 29.4046C33.8188 30.8721 32.3423 32.6416 30.1255 34.7096L4.63181 58.1355V72.652H11.5702V58.1355L37.0646 34.7096Z"
            fill="#3D5060"
          />
          <path
            d="M59.1265 72.6521L53.2226 54.332H46.2836L52.1874 72.6521H59.1265Z"
            fill="#EFC059"
          />
          <path
            d="M46.2836 54.3318H53.2227L53.222 54.3304L51.2209 48.0879L51.2223 48.092L45.8423 52.9552L46.2829 54.3304"
            fill="#3D5060"
          />
          <path
            d="M22.7694 21.2954C21.7703 21.8979 20.8988 22.6368 20.146 23.5026L25.3748 26.1C26.3829 24.0334 27.8268 22.4314 29.7078 21.2961C30.5369 20.7956 31.3938 20.4117 32.2757 20.1318C31.3133 19.7748 30.1616 19.5928 28.8148 19.5928C26.6639 19.5928 24.649 20.1601 22.7687 21.2954H22.7694Z"
            fill="#EFC059"
          />
          <path
            d="M54.3966 45.2225C59.1654 40.9522 62.373 37.1299 64.0202 33.7598C65.6652 30.3897 66.4881 26.768 66.4881 22.8967C66.4881 22.8478 66.4833 22.8016 66.4833 22.7527C66.4791 22.1661 66.4534 21.5863 66.4014 21.0163C66.3965 20.9618 66.3889 20.9081 66.3834 20.8536C66.3244 20.2594 66.2453 19.6728 66.1398 19.0972L66.1433 19.1089C65.6333 16.2923 64.5489 13.7136 62.8607 11.383C60.442 8.04668 57.0825 5.47758 52.7841 3.67431C48.4844 1.87242 43.5471 0.972168 37.9714 0.972168C36.7773 0.972168 35.6221 1.02525 34.4849 1.10245C38.6659 1.40299 42.4564 2.25431 45.8451 3.67431C50.1434 5.47758 53.5037 8.04668 55.9217 11.383C57.6098 13.7136 58.6943 16.2923 59.2042 19.1089L59.2008 19.0972C59.3062 19.6728 59.3853 20.2594 59.4443 20.8536C59.4491 20.9081 59.4575 20.9611 59.4623 21.0163C59.5144 21.5863 59.54 22.1661 59.5442 22.7527C59.5442 22.8009 59.5491 22.8478 59.5491 22.8967C59.5491 26.768 58.7255 30.3897 57.0811 33.7598C55.4339 37.1299 52.2263 40.9522 47.4575 45.2225L37.3809 54.3326H44.32L51.2223 48.0928L51.2209 48.0887L54.3966 45.2232V45.2225Z"
            fill="#EFC059"
          />
          <path
            d="M44.2832 48.092L44.2819 48.0879L46.5854 46.011L47.4576 45.2224C52.2263 40.952 55.4339 37.1298 57.0811 33.7597C58.7262 30.3896 59.5491 26.7679 59.5491 22.8966C59.5491 22.8477 59.5442 22.8015 59.5442 22.7526C59.54 22.166 59.5144 21.5862 59.4623 21.0162C59.4575 20.9617 59.4498 20.9079 59.4443 20.8535C59.3853 20.2593 59.3062 19.6727 59.2008 19.0971L59.2042 19.1088C58.6943 16.2922 57.6098 13.7135 55.9217 11.3829C53.503 8.04657 50.1434 5.47746 45.8451 3.6742C42.4564 2.25488 38.6659 1.40357 34.4849 1.10303C28.6497 1.49939 23.5576 2.89802 19.2288 5.32719C14.0548 8.22993 10.1256 12.1846 7.43907 17.1904L20.1467 23.5019C20.8995 22.6361 21.771 21.8978 22.7701 21.2946C24.6497 20.1593 26.6653 19.592 28.8162 19.592C30.163 19.592 31.3147 19.7733 32.2771 20.1311C33.3948 19.7761 34.5529 19.592 35.7553 19.592C37.9721 19.592 39.6685 20.0766 40.8446 21.0437C42.0193 22.0115 42.6076 23.364 42.6076 25.0983C42.6076 26.4997 42.2378 27.9363 41.4989 29.4038C40.7585 30.8714 39.2821 32.6409 37.0653 34.7089L11.5702 58.1355V72.6519H52.1874L46.2836 54.3318H37.3809L44.2832 48.092Z"
            fill="#F3F8F1"
          />
        </svg>
      </div>
    </div>
  );
};
