import React from "react";

export const Heart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_406_2122)">
        <path
          d="M15.9998 10.6998C15.9998 12.1998 13.7408 14.4318 11.9998 15.7618C10.2588 14.4318 7.99979 12.2018 7.99979 10.6998C7.92379 8.6938 10.9128 8.2998 10.9998 10.4538V10.9998C10.9998 11.265 11.1051 11.5194 11.2927 11.7069C11.4802 11.8944 11.7346 11.9998 11.9998 11.9998C12.265 11.9998 12.5194 11.8944 12.7069 11.7069C12.8944 11.5194 12.9998 11.265 12.9998 10.9998V10.4538C13.0868 8.2998 16.0758 8.6938 15.9998 10.6998ZM23.9998 12.3398V18.9998C23.9979 20.3253 23.4706 21.596 22.5333 22.5333C21.596 23.4706 20.3253 23.998 18.9998 23.9998H13.0838C6.08179 23.9998 0.469789 19.2078 0.0287894 12.8548C-0.0938447 11.1406 0.153175 9.41996 0.753135 7.80945C1.35309 6.19893 2.29199 4.73608 3.5063 3.51988C4.72061 2.30367 6.18199 1.36249 7.79157 0.760021C9.40114 0.157549 11.1213 -0.0921554 12.8358 0.0278035C15.9073 0.291441 18.7653 1.70725 20.8361 3.99098C22.9069 6.2747 24.0371 9.25725 23.9998 12.3398ZM17.9998 10.6998C18.0201 9.99624 17.8318 9.30239 17.4586 8.70561C17.0854 8.10883 16.544 7.6358 15.9026 7.34607C15.2611 7.05634 14.5482 6.96287 13.8538 7.07742C13.1593 7.19197 12.5142 7.50942 11.9998 7.9898C11.4854 7.50942 10.8403 7.19197 10.1458 7.07742C9.45134 6.96287 8.73849 7.05634 8.09703 7.34607C7.45557 7.6358 6.91416 8.10883 6.54096 8.70561C6.16777 9.30239 5.97948 9.99624 5.99979 10.6998C5.99979 13.9558 10.8738 17.4358 11.4298 17.8218C11.5972 17.9379 11.7961 18.0002 11.9998 18.0002C12.2035 18.0002 12.4024 17.9379 12.5698 17.8218C13.1258 17.4358 17.9998 13.9558 17.9998 10.6998Z"
          fill="#374957"
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2122">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
