import React from "react";

export const EllipseAndPuzzleDesktop = () => {
  return (
    <svg
      width="225"
      height="85"
      viewBox="0 0 225 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="41.3793" cy="40.8408" r="40" fill="#EFC059" />
      <ellipse
        cx="40.6898"
        cy="38.772"
        rx="36.3828"
        ry="28.2759"
        fill="#222222"
      />
      <ellipse
        cx="40.6896"
        cy="33.2549"
        rx="37.931"
        ry="28.2759"
        fill="#EFC059"
      />
      <path
        d="M111.435 52.4992V33.0412H113.55V52.4992H111.435ZM102.505 43.8042V41.7832H122.48V43.8042H102.505Z"
        fill="#F3F8F1"
      />
      <rect x="145" y="2.22021" width="80" height="80" fill="#3D5060" />
      <mask
        id="path-6-outside-1_89_3871"
        maskUnits="userSpaceOnUse"
        x="143"
        y="0.220215"
        width="58"
        height="84"
        fill="black"
      >
        <rect fill="white" x="143" y="0.220215" width="58" height="84" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.483 2.22021H145V82.2202H179.483V54.634C190.148 54.634 198.793 45.9885 198.793 35.3237C198.793 24.6589 190.148 16.0133 179.483 16.0133V2.22021Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.483 2.22021H145V82.2202H179.483V54.634C190.148 54.634 198.793 45.9885 198.793 35.3237C198.793 24.6589 190.148 16.0133 179.483 16.0133V2.22021Z"
        fill="#3D5060"
      />
      <path
        d="M145 2.22021V0.220215H143V2.22021H145ZM179.483 2.22021H181.483V0.220215H179.483V2.22021ZM145 82.2202H143V84.2202H145V82.2202ZM179.483 82.2202V84.2202H181.483V82.2202H179.483ZM179.483 54.634V52.634H177.483V54.634H179.483ZM179.483 16.0133H177.483V18.0133H179.483V16.0133ZM145 4.22021H179.483V0.220215H145V4.22021ZM147 82.2202V2.22021H143V82.2202H147ZM179.483 80.2202H145V84.2202H179.483V80.2202ZM177.483 54.634V82.2202H181.483V54.634H177.483ZM196.793 35.3237C196.793 44.8839 189.043 52.634 179.483 52.634V56.634C191.252 56.634 200.793 47.093 200.793 35.3237H196.793ZM179.483 18.0133C189.043 18.0133 196.793 25.7634 196.793 35.3237H200.793C200.793 23.5543 191.252 14.0133 179.483 14.0133V18.0133ZM177.483 2.22021V16.0133H181.483V2.22021H177.483Z"
        fill="#222222"
        mask="url(#path-6-outside-1_89_3871)"
      />
    </svg>
  );
};
