import React from "react";

// local imports
import { Heart } from "../../Svgs/WeltCards/Heart";
import { Pyramid } from "../../Svgs/WeltCards/Pyramid";
import { BigHeart } from "../../Svgs/WeltCards/BigHeart";
import { Comments } from "../../Svgs/WeltCards/Comments";
import { Play } from "../../Svgs/WeltCards/Play";
import { Chart } from "../../Svgs/WeltCards/Chart";
import { VideoCamera } from "../../Svgs/WeltCards/VideoCamera";
import { CommentUser } from "../../Svgs/WeltCards/CommentUser";
import { Smartphone } from "../../Svgs/WeltCards/Smartphone";
import { ELearning } from "../../Svgs/WeltCards/ELearning";
import { ChartTree } from "../../Svgs/WeltCards/ChartTree";
import { FlowerTulip } from "../../Svgs/WeltCards/FlowerTulip";

export const WeltCards = () => {
  return (
    <div
      id="services"
      className="flex flex-1 flex-col justify-start py-8 px-2.5 5xs:-mt-10 5xs:px-5 xxs:-mt-32 xxs:mb-14 sm:mb-24 md2:mt-0 md2:mb-0"
    >
      {/* content */}
      <div className="flex flex-col justify-start py-10 gap-2">
        <h1 className="font-Montserrat font-black uppercase tracking-tighter text-blue-600 text-5xl 5xs:text-5xl xxs:text-5xl sm:text-7xl 4xs:mb-1 5xs:tracking-normal 5xs:leading-7 xxs:leading-9 sm:leading-[50px]">
          Das beste
        </h1>
        <h2 className="font-Montserrat font-light uppercase text-yellow-500 tracking-tight -mt-3 5xs:mb-7 5xs:text-2xl 4xs:tracking-tighter 4xs:text-4xl 4xs:text-4xl xxs:text-5xl sm:text-7xl">
          Aus <span className="font-black">2w</span>elten
        </h2>

        <div className="font-thin break-words text-white font-Raleway text-xl 5xs:text-base xxs:text-2xl sm:text-2xl md2:text-2xl md2:w-[780px]">
          <p>
            2 Teams aus 2Welten für maximalen Impact. Unser Team „Keksfabrik“
            kümmert sich um den Kreativ-Part, den unser Team „Nigma“ mit der
            Power der Daten pragmatisch ins Ziel bringt.
          </p>
        </div>
      </div>

      {/* Grid cols  */}
      <div className="grid gap-8 grid-cols-2 5xs:grid-cols-1 4xs:grid-cols-1 3xs:grid-cols-1 xxs:grid-cols-2">
        {/* Card 1  */}
        <div className="flex-start 5xs:h-[708px] xxs:h-[708px] md:h-[708px] md2:w-[475px] isolate md:mb-56">
          {/* Gray card */}
          <div className="absolute bg-[#3D5060] z-10 5xs:w-[200px] 5xs:h-[720px] 4xs:w-[350px]  xxs:w-[360px] xxs:h-[748px] sm:w-[475px] md:w-[585px] md:h-[858px] md2:w-[625px] md2:h-[788px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
          {/* Yellow card  */}
          <div className="absolute bg-[#EFC059] z-10 5xs:w-[300px] 5xs:h-[720px] xxs:w-[360px] 4xs:w-[350px] xxs:h-[748px] sm:w-[475px] md:w-[585px] md:h-[858px] md2:w-[625px] md2:h-[788px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
          {/* Beige card */}
          <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[300px] 5xs:h-[720px] 4xs:w-[350px] xxs:w-[360px] xxs:h-[748px] sm:w-[475px] md:w-[585px] md:h-[858px] md2:w-[625px] md2:h-[788px] rounded-tl rounded-tr-[50px]">
            <div className="5xs:pt-10 md2:pt-14 sm:pt-16 5xs:pl-8 5xs:pr-10 xxs:pl-10 sm:pl-10 md2:pl-8">
              <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-3xl xxs:text-4xl sm:text-4xl md:text-4xl md2:text-4xl">
                Welt 1
              </h2>
              <h2 className="font-Montserrat tracking-tighter font-light text-yellow-600 uppercase 5xs:text-4xl md:text-4xl xxs:text-5xl sm:text-5xl md2:text-6xl">
                Pragmatik
              </h2>
            </div>

            {/* card content  */}
            <div className="5xs:py-8 xxs:py-8 md2:py-5 5xs:px-8 font-Raleway font-thin leading-6 5xs:text-base text-lg md:text-2xl md2:text-2xl gap-4">
              <p className="5xs:mb-5 xxs:mb-14 md:mb-16 md2:mb-10">
                Was wäre Kreation ohne die praktische Anwendung? Hier kommt
                unsere pragmatische Seite ins Spiel:
              </p>
              <div className="text-blue-700 leading-5 md:text-2xl md2:text-2xl">
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Heart />
                  </span>
                  Social-Media Kampagnen
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Pyramid />
                  </span>
                  Performance Marketing (SEA)
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <BigHeart />
                  </span>
                  UX Beratung und Umsetzung
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Comments />
                  </span>
                  Eventplanung
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Play />
                  </span>
                  Streaming
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Chart />
                  </span>
                  Datenbasierte Marken- und Marktanalysen
                </p>
              </div>
            </div>

            {/* Btns wrapper */}
            <div className="md:pt-10 sm:pt-5 md2:pt-0 xl:pt-0 px-10 pb-10">
              {/* Yellow btn */}
              <div className="bg-yellow-500 5xs:w-64 5xs:h-16 md2:w-[418px] md2:h-12 rounded-tr-2xl rounded-tl mb-5 cursor-pointer">
                <p className="py-1.5 px-5 font-bold text-lg md2:text-2xl">
                  <a
                    href="https://nigmanauten.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nigmanauten Website ansehen
                  </a>
                </p>
              </div>

              {/* Gray btn */}
              <div className="border-2 border-[#3D5060] 5xs:w-64 5xs:h-12 md2:w-[290px] md2:h-12 rounded-tr-2xl rounded-tl cursor-pointer">
                <p className="py-1.5 px-5 font-extrabold text-lg md2:text-2xl text-blue-700">
                  <a href="mailto:servus@nigmanauten.de"> Kontakt aufnehmen</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Card 2  */}

        <div className=" flex-start w-full 5xs:h-[708px] xxs:h-[708px] md:h-[708px] md2:w-[475px] isolate md:mb-56">
          {/* Gray card */}
          <div className="absolute bg-[#3D5060] z-10 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[698px] 4xs:w-[350px] 4xs:h-[698px] xxs:w-[360px] xxs:h-[748px] sm:w-[475px] md:w-[495px] md:h-[858px] md2:w-[625px] md2:h-[788px] -ml-1.5 rounded-tl rounded-tr-[50px]"></div>
          {/* Yellow card  */}
          <div className="absolute bg-[#EFC059] z-10 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[698px] xxs:w-[360px] xxs:h-[748px] sm:w-[475px] md:w-[495px] md:h-[858px] md2:w-[625px] md2:h-[788px] ml-1.5 pb-8 rounded-tl rounded-tr-[50px]"></div>
          {/* Beige card */}
          <div className="absolute bg-[#F3F8F1] z-20 5xs:w-[300px] 4xs:w-[350px] 5xs:h-[698px] xxs:w-[360px] xxs:h-[748px] sm:w-[475px] md:w-[495px] md:h-[858px] md2:w-[625px] md2:h-[788px] rounded-tl rounded-tr-[50px]">
            <div className="5xs:pt-10 md2:pt-14 sm:pt-16 5xs:pl-8 5xs:pr-10 xxs:pl-10 sm:pl-10 md2:pl-8">
              <h2 className="font-Montserrat tracking-tight font-black text-blue-700 uppercase 5xs:text-3xl xxs:text-4xl sm:text-4xl md:text-4xl md2:text-4xl">
                Welt 2
              </h2>
              <h2 className="font-Montserrat tracking-tighter font-light text-yellow-600 uppercase 5xs:text-4xl md:text-4xl xxs:text-5xl xxs:mb-2 sm:text-5xl md2:text-6xl">
                kreation
              </h2>
            </div>

            {/* card content  */}
            <div className="5xs:py-8 xxs:py-8 sm:py-10 md2:py-7 5xs:px-8 font-Raleway font-thin leading-6 5xs:text-base text-lg md:text-2xl md2:text-2xl gap-4">
              <p className="5xs:mb-5 xxs:mb-14 md:mb-10 md2:mb-12">
                Wir sind Storytelling Expert:innen und erschaffen neue Welten.
                Da ist die Kreation:
              </p>
              <div className="text-blue-700 leading-5 md:text-2xl md2:text-2xl">
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <VideoCamera />
                  </span>
                  Werbefilme
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <CommentUser />
                  </span>
                  Produkt- und Erklärfilme
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <Smartphone />
                  </span>
                  Social Media in Text, Bild und Film
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <ELearning />
                  </span>
                  E-Learnings + Web-Based Trainings
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <ChartTree />
                  </span>
                  Infographiken
                </p>
                <p className="py-1.5 flex items-center">
                  <span className="pr-2.5">
                    <FlowerTulip />
                  </span>
                  Markenentwicklung
                </p>
              </div>
            </div>

            {/* Btns wrapper */}
            <div className="pt-0 px-10 pb-10">
              {/* Yellow btn */}
              <div className="bg-yellow-500 5xs:w-64 5xs:h-16 md2:w-[418px] md2:h-12 rounded-tr-2xl rounded-tl mb-5 cursor-pointer">
                <p className="py-1.5 px-5 font-bold text-lg md2:text-2xl ">
                  <a
                    href="https://keksfabrik.tv/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Keksfabrik Website ansehen
                  </a>
                </p>
              </div>

              {/* Gray btn */}
              <div className="border-2 border-[#3D5060] 5xs:w-64 5xs:h-12 md2:w-[290px] md2:h-12 rounded-tr-2xl rounded-tl cursor-pointer">
                <p className="py-1.5 px-5 font-extrabold text-lg md2:text-2xl text-blue-700">
                  <a href="mailto:info@keksfabrik.tv">Kontakt aufnehmen</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of grid cols  */}
    </div>
  );
};
